import { useState, useEffect } from "react";
import { StarOutlined, DownOutlined } from "@ant-design/icons";
import { Rate, Button } from "antd";
import { orderBy } from "lodash";

const SliderTableFilter = (projectsData, setShowenData, property) => {
  const [sliderData, setSliderData] = useState(undefined);

  useEffect(() => {
    if (sliderData !== undefined) {
      setShowenData(() => {
        let filteredData = projectsData.filter((item) => {
          if (item[property] <= sliderData) {
            return item;
          } else return null;
        });
        return orderBy(filteredData, property, "desc");
      });
    }

    // eslint-disable-next-line
  }, [sliderData, setShowenData]);

  function searchOnTable(data) {
    setSliderData(data);
  }

  function resetRating() {
    setSliderData(undefined);
    setShowenData(projectsData);
  }

  return {
    filterDropdown: () => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Rate
          style={{
            color: "#12819c",
            border: "none",
          }}
          allowHalf
          character={<StarOutlined />}
          value={sliderData === undefined ? 0 : sliderData}
          onChange={(data) => {
            searchOnTable(data);
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "1rem",
          }}
        >
          <Button type="primary" onClick={resetRating}>
            Reset
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <DownOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
  };
};

export default SliderTableFilter;
