import { Modal, Spin } from "antd";
import { downloadZip } from "client-zip";
import { toastError } from "helpers/toasters";
import { useState, useEffect } from "react";
import { getProjectBrief } from "../network";
import { baseUrl } from "services";
import Logo from "assets/Images/logo.svg";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import Routes from "constants/ROUTES";
import { DownloadOutlined } from "@ant-design/icons";

const ProjectBrief = ({ onCancel, isVisible, projectId }) => {
	const [submittedBriefData, setSubmittedBriefData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getProjectBrief(
			projectId,
			(success) => {
				setIsLoading(false);
				setSubmittedBriefData(success.data);
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	}, [projectId]);

	async function handleDownloadDrawings() {
		async function getAllPaths() {
			const arr = [];
			await Promise.all(
				submittedBriefData.drawingsPath.map(async (imgPath) => {
					let data = await fetch(baseUrl + "/" + imgPath);
					arr.push(data);
					return arr;
				})
			);
			return arr;
		}
		const blob = await downloadZip([...(await getAllPaths())]).blob();
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = "drawings.zip";
		link.click();
		link.remove();
	}

	return (
		<Modal
			className="users-modal submitted"
			visible={isVisible}
			onCancel={onCancel}
			footer={false}
			centered
			width={"75vw"}
			bodyStyle={{
				position: "relative",
				minHeight: "450px",
			}}
		>
			{isLoading ? (
				<div
					className="spinner__container"
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
					}}
				>
					<Spin
						className="spinner"
						style={{
							position: "absolute",
							left: "50%",
							top: "50%",
							transform: "translate(-50%)",
						}}
					/>
				</div>
			) : (
				<>
					<div className="submitted__project-name">
						{submittedBriefData?.projectName}
					</div>
					<div className="submitted__wrapper">
						<h2>Service Type: {submittedBriefData?.service}</h2>
						<h2 className="h1 title mt-4">{submittedBriefData?.serviceType}</h2>
						<h5 className="my-3">
							{submittedBriefData?.serviceTypeItems?.length
								? submittedBriefData?.serviceTypeItems[0]
								: submittedBriefData?.otherServiceTypeItems}
						</h5>
						<div className="porperties d-flex flex-column mt-3 mb-2">
							<div className="project w-100 row justify-content-between">
								<div className="project-area col-md-4 mt-3 mb-2">
									<h5 className="title">Exact Project Area</h5>
									<h6>
										{submittedBriefData?.projectArea}
										<span className="measure">
											m<sup>2</sup>
										</span>
									</h6>
								</div>
								{submittedBriefData?.projectAddress && (
									<div className="project-address col-md-4 mt-3 mb-2">
										<h5 className="title">Project Address</h5>
										<h6>{submittedBriefData?.projectAddress}</h6>
									</div>
								)}
							</div>

							{submittedBriefData?.handoverrequirements?.length ||
							submittedBriefData?.otherHandoverRequirementAnswer ? (
								<div className="handover mt-3 mb-2">
									<h5 className="title">Handover Requirements</h5>
									<Row gutter={36}>
										{submittedBriefData?.handoverrequirements?.map(
											(item, i) => (
												<Col key={item._id}>
													<h6>{item}</h6>
												</Col>
											)
										)}
										{submittedBriefData?.otherHandoverRequirementAnswer && (
											<Col>
												<h6>
													{submittedBriefData?.otherHandoverRequirementAnswer}
												</h6>
											</Col>
										)}
									</Row>
								</div>
							) : null}

							{submittedBriefData?.serviceQuestions.map((question, _) => {
								if (question.answer || question.theOtherAnswer) {
									return (
										<div
											className="project-location col-md-4 mt-3 mb-2"
											key={_}
										>
											<h5 className="title">{question?.question}</h5>
											<h6>
												{question?.answer
													? question.answer
													: question.theOtherAnswer}
											</h6>
										</div>
									);
								} else return null;
							})}

							<div className="project-status mt-3 mb-2">
								<ul className="row justify-content-between p-0">
									<li className="col-md-4 col-lg-6 col-xl-4 mt-3 mb-2">
										<h5 className="status title">Project Status</h5>
										<h6>{submittedBriefData?.projectStatus}</h6>
									</li>
									{submittedBriefData?.drawingsPath.length ? (
										<li className="col-md-4 col-lg-6 col-xl-4 mt-3 mb-2">
											<button
												className="btn py-2 button"
												onClick={handleDownloadDrawings}
												style={{
													alignItems: "center",
												}}
											>
												<DownloadOutlined
													style={{
														marginInlineEnd: "0.5rem",
														fontSize: "1.25rem",
													}}
												/>
												Uploaded Drawings
											</button>
										</li>
									) : (
										<></>
									)}
								</ul>
							</div>

							{submittedBriefData?.comment ? (
								<div className="project-comments mt-3 mb-2">
									<h5 className="comments-title title">Extra Comments</h5>
									<p>{submittedBriefData.comment}</p>
								</div>
							) : null}
							{submittedBriefData?.optionalServices?.length ? (
								<div className="optional mt-3 mb-2">
									<h2 className="title d-flex align-items-center py-2">
										<span className="title-desc">
											<span className="me-1">
												Optional additional consultation services provided by
											</span>
											<Link to={Routes.HOME}>
												<img src={Logo} alt="Bidster" />
											</Link>
										</span>
									</h2>
									<ul className="p-0">
										{submittedBriefData?.optionalServices.map((item, i) => (
											<li key={i}>
												<h6>{item}</h6>
											</li>
										))}
									</ul>
								</div>
							) : null}
						</div>
					</div>
				</>
			)}
		</Modal>
	);
};

export default ProjectBrief;
