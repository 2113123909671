import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ROUTES, { homeRoute } from "constants/ROUTES";
// Main Elements
import Home from "modules/Home";
import SignIn from "modules/Authorization/Signin";
// Pages
import LandingPage from "modules/Pages/PagesModal/Tabs/LandingPage/LandingPage";
import Blog from "modules/Pages/PagesModal/Tabs/Blogs/Blog";

// Dashboard
import Projects from "modules/Pages/Dashboard/Tabs/Projects";
import PropertyOwner from "modules/Pages/Dashboard/Tabs/PropertyOwner";
import Pros from "modules/Pages/Dashboard/Tabs/Pros";
import SuperAdmin from "modules/Pages/Dashboard/Tabs/SuperAdmin";
// Templates
import Service from "modules/Pages/Templates/Tabs/Service";
import Quotations from "modules/Pages/Templates/Tabs/Quotations";
import TermsAndConditions from "modules/Pages/Templates/Tabs/Terms&Conditions";
import PrivicyAndPolicy from "modules/Pages/Templates/Tabs/PrivicyAndPolicy";
// Tokens
import FeesEquation from "modules/Pages/Tokens/Tabs/FeesEquation";
import TokenConversion from "modules/Pages/Tokens/Tabs/TokenConversion";
import TokenPackages from "modules/Pages/Tokens/Tabs/TokenPackages";
// Transactions
import AnnualyTransactions from "modules/Pages/Transactions/Tabs/Annualy";
import BiddingPerformance from "modules/Pages/ProjectStats/Tabs/BiddingPerformance";
import HiringPerformance from "modules/Pages/ProjectStats/Tabs/HiringPerformance";
import ContactUs from "modules/Pages/Inbox/Tabs/ContactUs";
import Feedback from "modules/Pages/Inbox/Tabs/Feedback";
import PageNotFound from "modules/Pages/PageNotFound/PageNotFound";
import UtilitiesMore from "modules/Pages/PagesModal/Tabs/UtilitiesAndMore/Utilities&more";

// custom Routes gards
import CustomAdminRoutesGard from "./Shared/CustomAdminRoutes";
import { isAdmin, isFinanceAdmin } from "helpers/adminRole";

import LogoutAdmin from "./Shared/LogoutAdmin";

const RoutesFile = () => {
	const {
		authorization: { token, roleName },
	} = useSelector((state) => state.auth);

	return (
		<Routes>
			<Route
				path={ROUTES.SIGNIN}
				element={!token ? <SignIn /> : <Navigate to={homeRoute} />}
			/>

			<Route
				path={ROUTES.HOME}
				element={!token ? <Navigate to={ROUTES.SIGNIN} /> : <Home />}
			>
				<Route path={ROUTES.PAGES}>
					<Route
						path={ROUTES.PAGES_LANDING}
						element={
							<CustomAdminRoutesGard
								hasRole={isAdmin(roleName)}
								children={<LandingPage />}
							/>
						}
					/>
					<Route
						path={ROUTES.BLOGS}
						element={
							<CustomAdminRoutesGard
								hasRole={isAdmin(roleName)}
								children={<Blog />}
							/>
						}
					/>
					<Route
						path={ROUTES.PAGES_UTILITIES}
						element={
							<CustomAdminRoutesGard
								hasRole={isAdmin(roleName)}
								children={<UtilitiesMore />}
							/>
						}
					/>
				</Route>

				<Route path={ROUTES.DASHBOARD}>
					<Route
						path={ROUTES.PROJECTS}
						element={
							<CustomAdminRoutesGard
								hasRole={isAdmin(roleName)}
								children={<Projects />}
							/>
						}
					/>
					<Route
						path={ROUTES.PROPERTY_OWNER}
						element={
							<CustomAdminRoutesGard
								hasRole={isAdmin(roleName)}
								children={<PropertyOwner />}
							/>
						}
					/>
					<Route
						path={ROUTES.PROS}
						element={
							<CustomAdminRoutesGard
								hasRole={isAdmin(roleName)}
								children={<Pros />}
							/>
						}
					/>
					<Route
						path={ROUTES.SUPER_ADMIN}
						element={
							roleName === "Super admin" ? (
								<SuperAdmin />
							) : (
								<Navigate to={"/" + homeRoute} />
							)
						}
					/>
				</Route>

				<Route path={ROUTES.TEMPLATES}>
					<Route
						path={ROUTES.SERVICE + "/:serviceId"}
						element={
							<CustomAdminRoutesGard
								hasRole={isAdmin(roleName)}
								children={<Service />}
							/>
						}
					/>
					<Route
						path={ROUTES.QUOTATIONS}
						element={
							<CustomAdminRoutesGard
								hasRole={isAdmin(roleName)}
								children={<Quotations />}
							/>
						}
					/>
					<Route
						path={ROUTES.TERMSANDCONDITIONS}
						element={
							<CustomAdminRoutesGard
								hasRole={isAdmin(roleName)}
								children={<TermsAndConditions />}
							/>
						}
					/>
					<Route
						path={ROUTES.PRIVACYANDPOLICY}
						element={
							<CustomAdminRoutesGard
								hasRole={isAdmin(roleName)}
								children={<PrivicyAndPolicy />}
							/>
						}
					/>
				</Route>

				<Route path={ROUTES.TOKENS}>
					<Route
						path={ROUTES.FEESE_QUATION}
						element={
							<CustomAdminRoutesGard
								hasRole={isFinanceAdmin(roleName)}
								children={<FeesEquation />}
							/>
						}
					/>
					<Route
						path={ROUTES.TOKEN_CONVERSION}
						element={
							<CustomAdminRoutesGard
								hasRole={isFinanceAdmin(roleName)}
								children={<TokenConversion />}
							/>
						}
					/>
					<Route
						path={ROUTES.TOKEN_PACKAGES}
						element={
							<CustomAdminRoutesGard
								hasRole={isFinanceAdmin(roleName)}
								children={<TokenPackages />}
							/>
						}
					/>
				</Route>

				<Route path={ROUTES.TRANSACTIONS}>
					<Route
						path={ROUTES.ANNUALY}
						element={
							<CustomAdminRoutesGard
								hasRole={isFinanceAdmin(roleName)}
								children={<AnnualyTransactions />}
							/>
						}
					/>
					<Route
						path={ROUTES.MONTHLY}
						element={
							<CustomAdminRoutesGard
								hasRole={isFinanceAdmin(roleName)}
								children={<AnnualyTransactions mode="month" />}
							/>
						}
					/>
				</Route>

				<Route path={ROUTES.PROJECT_STATS}>
					<Route
						path={ROUTES.BIDDING_PERFORMANCE}
						element={
							<CustomAdminRoutesGard
								hasRole={isFinanceAdmin(roleName)}
								children={<BiddingPerformance />}
							/>
						}
					/>
					<Route
						path={ROUTES.HIRING_PERFORMANCE}
						element={
							<CustomAdminRoutesGard
								hasRole={isFinanceAdmin(roleName)}
								children={<HiringPerformance />}
							/>
						}
					/>
				</Route>

				<Route path={ROUTES.INBOX}>
					<Route
						path={ROUTES.CONTACT_US}
						element={
							<CustomAdminRoutesGard
								hasRole={isAdmin(roleName)}
								children={<ContactUs />}
							/>
						}
					/>
					<Route
						path={ROUTES.FEEDBACK}
						element={
							<CustomAdminRoutesGard
								hasRole={isAdmin(roleName)}
								children={<Feedback />}
							/>
						}
					/>
				</Route>
			</Route>

			<Route path="logout" element={<LogoutAdmin />} />

			<Route path="*" element={<PageNotFound />} />
		</Routes>
	);
};

export default RoutesFile;
