import { useState, useEffect } from "react";
import { Modal, Form, Input, Radio, Button } from "antd";
import { editTokenPackage, addTokenPackage } from "../network";
import { toastError, toastSuccess } from "helpers/toasters";

const AddEditPackage = ({
  isModalVisible,
  onCancel,
  packageData,
  setAllPackages,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (packageData) {
      form.setFieldsValue({
        name: packageData.name,
        price: packageData.price,
        tokens: packageData.tokens,
        isActive: packageData.isActive,
      });
    } else {
      form.setFieldsValue({
        name: "",
        price: "",
        tokens: "",
        isActive: true,
      });
    }
  }, [form, packageData]);

  function onCancelModal() {
    onCancel();
  }

  function submit(value) {
    setIsLoading(true);
    let { name, price, tokens, isActive } = value;

    if (packageData) {
      editTokenPackage(
        { name, price, tokens, isActive },
        packageData._id,
        (success) => {
          setIsLoading(false);
          toastSuccess(success.message);
          setAllPackages((prev) => {
            return prev.map((item) => {
              if (item._id === packageData._id) {
                return { ...item, name, price, tokens, isActive };
              } else return item;
            });
          });

          onCancel();
        },
        (fail) => {
          setIsLoading(false);
          if (fail?.data?.error) {
            toastError(fail.data.error);
          } else {
            toastError();
          }
        }
      );
    } else {
      addTokenPackage(
        { name, tokens, price, isActive },
        (success) => {
          const { _id } = success.data;
          setIsLoading(false);
          toastSuccess(success.message);
          onCancel();
          setAllPackages((prev) => [
            ...prev,
            { name, tokens, price, isActive, _id },
          ]);
        },
        (fail) => {
          setIsLoading(false);
          onCancel();
          if (fail?.data?.error) {
            toastError(fail.data.error);
          } else {
            toastError();
          }
        }
      );
    }
  }

  return (
    <Modal
      className="packages__add-modal"
      visible={isModalVisible}
      onCancel={onCancelModal}
      footer={false}
    >
      <header>
        <h6 className="main-color my-3 text-center">
          {packageData ? "Edit Package" : "Add New Package"}
        </h6>
      </header>
      <Form
        form={form}
        onFinish={submit}
        layout={"vertical"}
        requiredMark={false}
      >
        <Form.Item
          name="name"
          label="Package Name"
          rules={[
            {
              required: true,
              message: "Package name is required.",
            },
          ]}
        >
          <Input type="text" placeholder="package name ..." />
        </Form.Item>
        <Form.Item
          name="tokens"
          label="Tokens"
          rules={[
            {
              required: true,
              message: "Number of tokens is required.",
            },
          ]}
        >
          <Input type="number" min={0} placeholder="Tokens number" />
        </Form.Item>

        <Form.Item
          name="price"
          label="Price"
          colon={false}
          rules={[
            {
              required: true,
              message: "Price is required.",
            },
          ]}
        >
          <Input type="number" min={0} placeholder="Tokens price" />
        </Form.Item>

        <Form.Item
          className="d-flex justify-content-between"
          required
          name="isActive"
        >
          <Radio.Group>
            <Radio value={true}>Activate</Radio>
            <Radio value={false}>Deactivate</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item className="tokens__button-wrapper">
          <Button
            className="bap-primary-button bap-button"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            {packageData ? "Edit" : "Add"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditPackage;
