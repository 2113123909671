export const ACCOUNT_TYPES = {
  propertyOwner: {
    _id: "625be6db995eb93c8f7bfdbf",
    accountType: "Home Owner",
  },
  pro: {
    _id: "625be6e5995eb93c8f7bfdc1",
    accountType: "Pro",
  },
};
