export const en = {
  landingPage: "Landing Page",
  uploadImage: "Upload Image",
  uploadMediaEn: "Upload Media",
  uploadMediaAr: "تحميل صوره",
  uploadIcon: "Upload Icon",
  uploadEnImage: "Upload English Image",
  uploadArImage: "تحميل صوره",
  inputTitle: "Banner Title",
  save: "Save",
  edit: "Edit",
  delete: "Delete",
  landingPageBanner: "Landing Page - Banner",
  landingPageHomeServices: "Landing Page - Professional Services Banner",
  landingPageUtilitiesAndMore: "Landing Page - Utilities & More ",
  landingPageHowIt: "Landing Page - How it Works",
  qAndA: "Q & A",
  bannerTitle: "Banner Title",
  bannerText: "Banner Text",
  websiteBannerText: "Website Banner Text",
  mobileBannerText: "Mobile Banner Text",
  fullName: "FullName",
  name: "Name",
  client: "Client",
  status: "Status",
  bidders: "Bidders",
  service: "Service",
  submission: "Submission",
  expirey: "Expirey",
  hired: "Hired",
  rating: "Rating",
  addService: "Add. Service",
  assign: "Assign",
  shortlisting: "Shortlisting",
  quotations: "Quotations",
  propertyOwners: "Property Owners",
  userName: "User name",
  email: "Email",
  mobile: "Mobile",
  projects: "Projects",
  signupDate: "Sign Up Date",
  activity: "Activity",
  activate: "Activate",
  deActive: "deactive",
  diActivate: "Deactivate",
  deactivate: "Deactivate",
  areYouSureActivate: "Are you sure you want to activate",
  areYouSureDiactivate: "Are you sure you want to deactivate",
  areYouSureVerify: "Are you sure you want to verify ",
  verify: "Verify",
  signUpDate: "Sign Up Date",
  isEmailVerified:"Verification",
  active: "Active",
  inActive: "In Active",
  pros: "Pros",
  validation: "Validation",
  validate: "Validate",
  complete: "Complete",
  empty: "Empty",
  filterBySubmittionDate: "Filer by submission date",
  admin: "Admin",
  superAdmin: "Super Admin",
  pleaseFillAllFields: "Please, fill all required field",
  provideValidEmail: "Please, provide a valid email.",
  password: "Password",
  role: "Role",
};
