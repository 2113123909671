import { createSlice } from "@reduxjs/toolkit";

export const authorizationSlice = createSlice({
  name: "language",
  initialState: {
    lang: "en",
    dir: "ltr",
  },
  reducers: {
    changeLang: (state, action) => {
      state.lang = state.lang === "en" ? "ar" : "en";
      state.dir = state.dir === "ltr" ? "rtl" : "ltr";
    },
  },
});

const { reducer, actions } = authorizationSlice;
export const { changeLang } = actions;
export default reducer;
