import { useState } from "react";
import { Modal, Button } from "antd";
import { toastSuccess, toastError } from "helpers/toasters";
import { useTranslation } from "react-i18next";
import {
  postActivateUser,
  postActivateAdmin,
  postVerifyAdmin,
  postVerifyUser,
} from "../network";
import CustomTooltip from "modules/components/CustomTooltip";

const VerifyUserModal = ({
  setData,
  setUsers,
  user,
  onCancel,
  visible,
  isAdmin,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { userId, isUserActive } = user;

  function confirm() {
    setIsLoading(true);

    let payload = {
      isEmailVerified: true,
    };

    if (isAdmin) {
      payload.adminId = userId;
      postVerifyAdmin(
        payload,
        (success) => {
          setIsLoading(false);
          toastSuccess(success.message);
          setUsers((users) => {
            let filteredUsers = users.filter((item) => {
              if (item.userId === userId) {
                item.isEmailVerified = !isUserActive;
              }
              return user;
            });
            return filteredUsers;
          });

          setData((users) => {
            let filteredUsers = users.filter((item) => {
              if (item.userId === userId) {
                item.isEmailVerified = true;
              }
              return user;
            });
            return filteredUsers;
          });

          onCancel();
        },
        (fail) => {
          onCancel();
          setIsLoading(false);
          if (fail?.data?.error) {
            toastError(fail.data.error);
          } else {
            toastError();
          }
        }
      );
    } else {
	console.log(" User")
      payload.userId = userId;
      postVerifyUser(
        payload,
        (success) => {
			console.log(success)
          setIsLoading(false);
          toastSuccess(success.message);
          onCancel();
          setUsers((users) => {
            let filteredUsers = users.filter((item) => {
              if (item.userId === userId) {
                item.isEmailVerified = true;
              }
              return user;
            });
            return filteredUsers;
          });
        },
        (fail) => {
			console.log(fail)
			setIsLoading(false);
          if (fail?.data?.error) {
            toastError(fail.data.error);
          } else {
            toastError();
          }
        }
      );
    }
  }

  return (
    <>
      <Modal
        className='custom-modal'
        onCancel={onCancel}
        visible={visible}
        footer={null}
        centered
      >
        <div className='custom-modal__text'>
          {t("areYouSureVerify")} "
          <CustomTooltip text={user.userName} count={15} />" ?
        </div>

        <div className='custom-modal__controllers'>
          <Button
            loading={isLoading}
            className={isUserActive ? "danger-button" : "primary-button"}
            onClick={confirm}
          >
            {t("verify")}
          </Button>
          <Button className='secondary-button' onClick={onCancel}>
            cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default VerifyUserModal;
