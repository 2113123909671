import { useState } from "react";
import { Modal, Button } from "antd";
import { deleteClientProject } from "modules/Pages/Dashboard/network";
import { toastSuccess, toastError } from "helpers/toasters";

const DeleteModal = ({
	project,
	onCancel,
	deleteModalVisibility,
	setIsReload,
}) => {
	const { projectId, projectName } = project;
	const [isLoading, setIsLoading] = useState(false);
	function confirm() {
		setIsLoading(true);
		deleteClientProject(
			{ projectId },
			(success) => {
				setIsLoading(false);
				toastSuccess(success.message);
				setIsReload((prev) => !prev);

				onCancel();
			},
			(fail) => {
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
					setIsLoading(false);
				} else {
					toastError();
					setIsLoading(false);
				}
			}
		);
	}

	return (
		<>
			<Modal
				className="custom-modal"
				onCancel={onCancel}
				visible={deleteModalVisibility}
				footer={null}
				centered>
				<p className="custom-modal__text">Delete "{projectName}" ?</p>
				<div className="custom-modal__controllers">
					<Button
						loading={isLoading}
						className="danger-button"
						onClick={confirm}>
						Delete
					</Button>
					<Button className="secondary-button" onClick={onCancel}>
						cancel
					</Button>
				</div>
			</Modal>
		</>
	);
};

export default DeleteModal;
