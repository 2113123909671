import { useEffect, useState } from "react";
import { Modal, Input, Radio, Spin } from "antd";
import { getUserFeedback, getUserSuggestions } from "../../network";
import { toastError } from "helpers/toasters";

const FeedbackModal = ({ visible, onCancel, user, role }) => {
	const [suggesstion, setSuggesstion] = useState(null);
	const [feedbacks, setFeedbacks] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const answers = new Array(5);
	answers.fill(null);

	const { userId, projectId } = user;

	useEffect(() => {
		const payload = {
			userId,
			projectId,
		};
		setIsLoading(true);

		getUserFeedback(
			payload,
			(success) => {
				setIsLoading(false);
				setFeedbacks(success.data[0]);
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);

		getUserSuggestions(
			payload,
			(success) => {
				setIsLoading(false);
				setSuggesstion(success.data);
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, [userId, projectId]);
	return (
		<Modal
			className="feedback__modal feedback"
			visible={visible}
			onCancel={onCancel}
			footer={null}
			width={"75vw"}
			centered>
			<div className="feedback">
				<header className="feedback__header">
					<h2>{role} Survey</h2>
					<div>
						{user.userName} - {user.projectName}
					</div>
				</header>
				<div className="feedback__container">
					<div className="feedback__questions">
						{isLoading ? (
							<Spin className="spin" />
						) : (
							<>
								{feedbacks?.listOfQA?.map(
									(question, _idx) =>
										question.questionId.questionType === 2 && (
											<div className="question" key={_idx}>
												<div className="question__header">
													<span />
													{question.questionId.question}
												</div>
												<div className="question__answers">
													<ul className="answer">
														<Radio.Group
															value={question.textAnswer}
															className="answer__wrapper">
															{question.questionId.listOfOptions.map(
																(item, _idx) => (
																	<Radio
																		value={item}
																		disabled={item !== question.textAnswer}>
																		{item}
																	</Radio>
																)
															)}
														</Radio.Group>
													</ul>
												</div>
											</div>
										)
								)}
								<div className="question__header">
									<span />
									Please rate Bidster the regarding following:
								</div>
								{feedbacks?.listOfQA?.map(
									(question, _idx) =>
										question.questionType === 1 && (
											<div className="question" key={_idx}>
												<div className="question__header quesiton-header-sm">
													{_idx + 1} . {question.question}
												</div>

												<div className="question__rating">
													<div className="text">{question.lowestAnswer}</div>
													<ul className="rates">
														{answers.map((item, _idx) => (
															<li
																key={_idx}
																className={`rate ${
																	question.valueAnswer === _idx + 1
																		? "active"
																		: "disabled"
																}`}>
																<span>{_idx + 1}</span>
															</li>
														))}
													</ul>
													<div className="text">{question.highestAnswer}</div>
												</div>
											</div>
										)
								)}
								{suggesstion?.suggestion && (
									<div className="question ms-3">
										<div className="question__header quesiton-header-sm">
											9. Do you have any suggestions for us?
										</div>

										<Input.TextArea
											className="question__input"
											value={suggesstion.suggestion}
											disabled
										/>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default FeedbackModal;
