import { useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import { getAvarageFeedbacks } from "../../network";
import { toastError } from "helpers/toasters";

const AvarageFeedback = ({ visible, onCancel, role, accountTypeId }) => {
	const [feedbacks, setFeedbacks] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const answers = new Array(5);
	answers.fill(null);

	useEffect(() => {
		setIsLoading(true);
		getAvarageFeedbacks(
			accountTypeId,
			(success) => {
				setIsLoading(false);
				setFeedbacks(success.data);
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, [accountTypeId]);

	return (
		<Modal
			className="feedback__modal feedback"
			visible={visible}
			onCancel={onCancel}
			footer={null}
			width={"75vw"}
			centered>
			<div className="feedback">
				<header className="feedback__header">
					<h2>{role} Survey</h2>
				</header>
				<div className="feedback__container">
					<div className="feedback__questions">
						{isLoading ? (
							<Spin className="spin" />
						) : (
							<>
								{feedbacks?.map((item, _idx) => {
									console.log(item);
									return (
										<div className="question" key={_idx}>
											<div className="question__header quesiton-header-sm">
												{_idx + 1} . {item.question}
											</div>
											<div className="question__rating">
												<div className="text">{item.lowestAnswer.en}</div>
												<ul className="rates">
													{answers.map((rate, _idx) => (
														<li
															key={_idx}
															className={`rate ${
																item.average === _idx + 1
																	? "active"
																	: "disabled"
															}`}>
															<span>{_idx + 1}</span>
														</li>
													))}
												</ul>
												<div className="text">{item.highestAnswer.en}</div>
											</div>
										</div>
									);
								})}
							</>
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default AvarageFeedback;
