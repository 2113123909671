import { useState } from "react";
import { Modal, Row, Col, Form, Input, Select, Radio, Button } from "antd";
import { useTranslation } from "react-i18next";
import { addNewAdmin, editAdmin } from "../network";
import { toastError, toastSuccess } from "helpers/toasters";
import personImg from "assets/Icons/person.svg";

const AddEditAdminModal = ({
	visible,
	onCancel,
	setData,
	setShowenData,
	userToEdit,
	roles,
}) => {
	const [loading, setLoading] = useState(false);
	const { form } = Form.useForm();
	const { t } = useTranslation();
	let initialValues = {
		userName: "",
		name: "",
		email: "",
		password: "",
		isActive: true,
	};

	if (userToEdit) {
		const { userName, name, email, isSuperAdmin, roleId } = userToEdit;

		initialValues = {
			userName,
			name,
			email,
			isSuperAdmin,
			roleId,
		};
	}

	function submit(data) {
		setLoading(true);
		const { userName, name, email, password, roleId } = data;
		if (userToEdit) {
			editAdmin(
				userToEdit.userId,
				{ userName, name, email, ...(password && { password }), roleId },
				(success) => {
					setLoading(false);
					let userData = {
						...success.data[0],
						userId: success.data[0]._id,
						isUserActive: success.data[0].isActive,
					};

					setShowenData((prev) => {
						let newData = prev.map((item) => {
							if (item.userId === userToEdit.userId) {
								item = userData;
							}
							return item;
						});
						return newData;
					});

					setData((prev) => {
						let newData = prev.map((item) => {
							if (item.userId === userToEdit.userId) {
								item = userData;
							}
							return item;
						});
						return newData;
					});

					toastSuccess("Admin Updated Successfully");
					onCancel();
				},
				(fail) => {
					if (fail?.data?.error) {
						toastError(fail?.data?.error);
					} else {
						toastError();
					}
				}
			);
		} else {
			const { isActive } = data;
			addNewAdmin(
				{
					userName,
					name,
					email,
					password,
					roleId,
					isActive,
				},
				(success) => {
					setLoading(false);
					toastSuccess("Admin Added Successfully");
					let addedUserData = success.data[0];

					let data = {
						...addedUserData,
						isUserActive: addedUserData.isActive,
						roleName: addedUserData.roleName,
						roleId: addedUserData.roleId,
						userId: addedUserData._id,
					};

					setShowenData((prev) => {
						return [data, ...prev];
					});

					setData((prev) => {
						return [data, ...prev];
					});

					onCancel();
				},
				(fail) => {
					onCancel();
					if (fail?.data?.error) {
						toastError(fail?.data?.error);
					} else {
						toastError();
					}
				}
			);
		}
	}

	return (
		<Modal
			className="users-modal"
			visible={visible}
			onCancel={onCancel}
			footer={false}
			centered
		>
			<Form onFinish={submit} form={form} initialValues={initialValues}>
				<Row gutter={[32, 32]}>
					<Col md={14}>
						<Form.Item
							name="userName"
							colon={false}
							required
							rules={[
								{
									required: true,
									message: "Please, provide admin user name.",
								},
							]}
							requiredMark={false}
						>
							<Input placeholder={t("userName")} />
						</Form.Item>
						<Form.Item
							name="name"
							rules={[
								{
									required: true,
									message: "Please, provide admin full name.",
								},
							]}
							colon={false}
							required
							requiredMark={false}
						>
							<Input placeholder={"Full Name"} />
						</Form.Item>
						<Form.Item
							name="email"
							colon={false}
							required
							requiredMark={false}
							rules={[
								{
									required: true,
									message: "Please, provide admin email.",
								},
								{
									type: "email",
									message: t("provideValidEmail"),
								},
							]}
						>
							<Input placeholder={t("email")} />
						</Form.Item>
						<Form.Item
							name="password"
							colon={false}
							rules={[
								!userToEdit && {
									required: true,
									message: "Please, provide admin password.",
								},
							]}
							requiredMark={false}
						>
							<Input.Password placeholder={t("password")} />
						</Form.Item>
						{!userToEdit && (
							<Form.Item
								name="isActive"
								colon={false}
								required
								requiredMark={false}
							>
								<div className="d-flex align-items-center">
									<img src={personImg} className="me-2" alt="person" />
									<Radio.Group defaultValue={true}>
										<Radio value={true}>{t("activate")}</Radio>
										<Radio value={false}>{t("deactivate")}</Radio>
									</Radio.Group>
								</div>
							</Form.Item>
						)}
					</Col>
					<Col md={10}>
						<Form.Item
							name="roleId"
							required
							requiredMark={false}
							colon={false}
							rules={[
								{
									required: true,
									message: "Please, provide admin role.",
								},
							]}
						>
							<Select placeholder="Roles" aria-placeholder="Roles">
								<>
									{roles?.map((role) => (
										<Select.Option value={role._id} key={role._id}>
											{role.name}
										</Select.Option>
									))}
								</>
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Form.Item className="signin__submit">
					<Button type="primary" htmlType="submit" loading={loading}>
						{userToEdit ? "Edit Admin" : "Add Admin"}
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default AddEditAdminModal;
