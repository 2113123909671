import { useState, useEffect } from "react";
import { Modal, Spin, Rate, Menu, Dropdown, Space } from "antd";
import { getProRating } from "modules/Pages/Dashboard/network";
import { toastError } from "helpers/toasters";
import { DownOutlined, StarOutlined } from "@ant-design/icons";
import DeleteReviewIcon from "assets/Icons/delete-red.svg";
import { DeleteRatingModal } from "../components/DeleteRatingProjectFromPro";

export const ProRatingAndReviews = ({ isVisible, onCancel, proId }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [proData, setProData] = useState([]);
	const [isRatingDeleteModalOpened, setIsRatingDeleteModalOpened] =
		useState(false);
	const [selectdProjectId, setSelectdProjectId] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		getProRating(
			proId,
			(success) => {
				setIsLoading(false);
				setProData(success.data);
			},
			(fail) => {
				if (fail.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, [proId]);

	const menu = (rates) => {
		let filteredData = rates.map((rate, index) => {
			return {
				key: index,
				label: (
					<div>
						<div className=" text-center">
							{rate.rateQuestionId.rateQuestion}
						</div>
						<Rate
							style={{
								color: "#12819c",
								border: "none",
							}}
							className="rate__value"
							allowHalf
							disabled
							character={<StarOutlined />}
							defaultValue={rate.rate}
						/>
					</div>
				),
			};
		});
		return <Menu items={filteredData} />;
	};

	function handleCancel() {
		onCancel();
	}

	function deleteRating(projectId) {
		setIsRatingDeleteModalOpened(true);
		setSelectdProjectId(projectId);
	}

	return (
		<>
			<Modal
				className="proRatings-modal"
				visible={isVisible}
				onCancel={handleCancel}
				footer={false}
				width={"75vw"}
				centered
			>
				{isLoading ? (
					<Spin className="portofolio__spin" />
				) : (
					<div>
						<header
							style={{
								fontSize: "1.25rem",
								color: "#12819c",
								textAlign: "center",
								marginBottom: "0.5rem",
							}}
							className="proRatings-modal__header"
						>
							<div style={{fontWeight: "bold"}}>Reviews</div>
						</header>
						<div className="proRatings-modal__container">
							<div className="proRatings-modal__preview">
								{proData?.length ? (
									<>
										{proData.map((item, _idx) => (
											<div className="review">
												<div className="review__rate-date">
													<Rate
														style={{
															color: "#12819c",
															border: "none",
														}}
														className="main-rate"
														allowHalf
														disabled
														character={<StarOutlined />}
														defaultValue={item.average}
													/>
													<div className="rates-date">
														<div className="date">{item.createdAt}</div>
														<div className="rates__dropdown">
															<Dropdown
																placement="bottomRight"
																overlay={() => menu(item.rates)}
															>
																<Space>
																	<StarOutlined
																		style={{
																			color: "#12819c",
																			border: "none",
																		}}
																	/>
																	<DownOutlined
																		style={{
																			color: "#707070",
																		}}
																	/>
																</Space>
															</Dropdown>
														</div>
													</div>
												</div>
												<div
													className="review__comment"
													style={{
														fontWeight: "bold",
														marginBottom: ".5rem",
													}}
												>
													{item.projectName}
												</div>
												<div className="review__comment">{item.comment}</div>
												<div className="review__client-delete">
													<div className="by">By: {item.clientName}</div>
													{item?.comment?.length ? (
														<div
															role="button"
															onClick={() => deleteRating(item.projectId)}
															className="delete"
														>
															<img src={DeleteReviewIcon} alt="delete icon" />{" "}
															Delete
														</div>
													) : null}
												</div>
											</div>
										))}
									</>
								) : (
									<div className="empty-contianer">
										<div className="empty">There’re no reviews yet</div>
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</Modal>
			{isRatingDeleteModalOpened && (
				<DeleteRatingModal
					deleteModalVisibility={isRatingDeleteModalOpened}
					onCancel={() => setIsRatingDeleteModalOpened(false)}
					projectId={selectdProjectId}
					proId={proId}
					setData={setProData}
				/>
			)}
		</>
	);
};
