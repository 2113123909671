import { useState, useEffect } from "react";
import { Modal, Checkbox, Button, Input } from "antd";
import moment from "moment/moment";
import Masonry from "react-masonry-css";
import { baseUrl } from "services";
import { approveProPortofolio } from "../network";
import { toastError, toastSuccess } from "helpers/toasters";

const ImagesApprovalModal = ({
	isVisible,
	onCancel,
	setProProjects,
	project,
	rerenderProsOnCancel,
}) => {
	const [approvedImages, setApprovedImages] = useState([]);
	const [rejectionMessage, setRejectionMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);

	// masonry-breakpoint-objects
	const breakpointColumnsObj = {
		default: 2,
		1100: 2,
		500: 1,
	};

	useEffect(() => {
		let numberOfPenddingImages = 0;
		project.imageDetails.filter((image) => {
			if (!image.isImgApproved && !image.isRejected) {
				numberOfPenddingImages += 1;
			}
			return null;
		});

		if (approvedImages.length === numberOfPenddingImages) {
			setIsDisabled(false);
		} else if (rejectionMessage) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	}, [project.imageDetails, approvedImages.length, rejectionMessage]);

	function submitProjectApproval() {
		setIsLoading(true);
		if (!isDisabled) {
			const payload = {
				projectId: project._id,
				approvals: approvedImages,
				regectionMessage: rejectionMessage,
			};
			approveProPortofolio(
				payload,
				(success) => {
					setIsLoading(false);
					toastSuccess(success.message);
					setProProjects((prev) => {
						const projects = prev.map((item) => {
							if (project._id === item._id) {
								item.isReviewed = true;
							}
							return item;
						});
						return projects;
					});
					rerenderProsOnCancel(true);
					onCancel();
				},
				(fail) => {
					setIsLoading(false);
					if (fail?.data?.error) {
						toastError(fail?.data?.error);
					} else {
						toastError();
					}
				}
			);
		}
	}

	function handleOnCancel() {
		onCancel();
	}

	function handleOnChange(data) {
		let arr = [];

		data.forEach((el) => {
			arr.push({ imageId: el._id, isImgApproved: true });
		});
		setApprovedImages(arr);
	}

	return (
		<Modal
			visible={isVisible}
			onCancel={handleOnCancel}
			footer={false}
			width={"50vw"}
			className={"single-project"}
			centered
		>
			<div className="single-project__header">
				<div className="item">
					<div className="title">Project Name: </div>
					{project.projectName}
				</div>
				<div className="item">
					<div className="title">Date: </div>
					{moment(project.createdAt).format("L")}
				</div>
				<div className="item">
					<div className="title">Service Type: </div>
					{project.service}
				</div>

				<Checkbox.Group onChange={handleOnChange}>
					<Masonry
						breakpointCols={breakpointColumnsObj}
						className="my-masonry-grid"
						columnClassName="my-masonry-grid_column"
					>
						{project.imageDetails.map((image) => {
							return (
								<div className="single-project__image" key={image._id}>
									<figure>
										<img src={baseUrl + "/" + image.imagePath} alt="" />
										{
											<>
												{!image?.isRejected && !image?.isImgApproved ? (
													<Checkbox value={image} className="checkbox" />
												) : image?.isRejected ? (
													<div
														className="checkbox"
														style={{
															width: "0.75rem",
															height: "0.75rem",
															background: "red",
															borderRadius: "50%",
														}}
													/>
												) : (
													<div
														className="checkbox"
														style={{
															width: "0.75rem",
															height: "0.75rem",
															background: "green",
															borderRadius: "50%",
														}}
													/>
												)}
											</>
										}
									</figure>
								</div>
							);
						})}
					</Masonry>
				</Checkbox.Group>
				{!project.isReviewed && (
					<>
						<div className="single-project__text-area">
							<Input.TextArea
								value={rejectionMessage}
								onChange={(e) => setRejectionMessage(e.target.value)}
								placeholder={"Reason for rejected content"}
							/>
						</div>
						<div className="single-project__btn-wrapper">
							<Button
								type="primary"
								loading={isLoading}
								disabled={isDisabled}
								onClick={submitProjectApproval}
							>
								Confirm
							</Button>
						</div>
					</>
				)}
			</div>
		</Modal>
	);
};
export default ImagesApprovalModal;
