import { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button } from "antd";
import { getAllFeesEquations, editFeesEquations } from "../network";
import { toastSuccess, toastError } from "helpers/toasters";

const FeesEquation = () => {
  const [biddingFeesData, setBiddingFeesData] = useState([]);
  const [areasData, setAreasData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    setIsDisabled(true);
    getAllFeesEquations(
      (success) => {
        success.data.map((item) => {
          setIsDisabled(false);
          const { feesEquationId, fees } = item;
          form.setFieldsValue({
            [feesEquationId]: fees,
          });

          if (!item.from && !item.to) {
            setBiddingFeesData((prev) => [...prev, item]);
          } else {
            setAreasData((prev) => [...prev, item]);
          }
          return null;
        });
      },
      (fail) => {
        setIsDisabled(false);
        if (fail?.data?.error) {
          toastError(fail?.data?.error);
        } else {
          toastError();
        }
      }
    );
  }, [form]);

  function submit(values) {
    let keys = Object.keys(values);
    let payload = [];

    for (let i = 0; i < keys.length; i++) {
      let fees = values[keys[i]];
      if (fees) {
        payload.push({ _id: keys[i], fees: +fees });
      }
    }
    if (payload.length === biddingFeesData.length + areasData.length) {
      setIsLoading(true);
      setHasError(false);
      editFeesEquations(
        payload,
        (success) => {
          toastSuccess(success.message);
          setIsLoading(false);
        },
        (fail) => {
          setIsLoading(false);
          if (fail?.data?.error) {
            toastError(fail?.data?.error);
          } else {
            toastError();
          }
        }
      );
    } else {
      setHasError(true);
    }
  }

  function onFieldsChange(_, fields) {
    let numberOfFieldsLength = Object.keys(fields).length;
    Object.keys(fields).forEach((key) => {
      if (fields[key] !== "") {
        numberOfFieldsLength -= 1;
      }
    });

    if (numberOfFieldsLength) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }

  return (
    <section className="tokens main-section">
      <header className="main-header">
        <h2>Fees Equation</h2>
      </header>
      <div className="box-shadow">
        <div className="tokens__bidding">
          <Row gutter={[16, 16]}>
            <Col md={8}>
              <h6 className="tokens__head">Service Type</h6>
            </Col>
            <Col md={16}>
              <h6 className="tokens__head">Bidding Fee EGP/ m2</h6>
            </Col>
          </Row>
          <Form form={form} onFinish={submit} onValuesChange={onFieldsChange}>
            {biddingFeesData?.length
              ? biddingFeesData.map((item, id) => (
                  <Row gutter={[16, 16]} className="item" key={id}>
                    <Col md={8}>
                      <div className="tokens__title">{item.service}</div>
                    </Col>
                    <Col md={16}>
                      <Form.Item name={item.feesEquationId}>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                ))
              : null}
            <div className="tokens__areas">
              <Row gutter={[16, 16]}>
                <Col md={8}>
                  <h6 className="tokens__head">
                    Biddings Fee EGP/m<sup>2</sup>
                  </h6>
                </Col>
                <Col md={4}>
                  <h6 className="tokens__head">
                    Area (m<sup>2</sup>)
                  </h6>
                </Col>
                <Col md={12}>
                  <h6 className="tokens__head">EGY</h6>
                </Col>
              </Row>
              {biddingFeesData?.length
                ? areasData.map((item, id) => (
                    <Row gutter={[16, 16]} className="item" key={id}>
                      {id === 0 ? (
                        <Col md={8}>
                          <div className="tokens__title">{item.service}</div>
                        </Col>
                      ) : (
                        <Col md={8} />
                      )}
                      <Col md={4}>
                        {item.from === null && item.to ? (
                          <div className="tokens__area">
                            Less Than {item.to}
                          </div>
                        ) : item.to === null && item.from ? (
                          <div className="tokens__area">
                            {item.from} {"<"}
                          </div>
                        ) : (
                          <div className="tokens__area">
                            {item.from} - {item.to}
                          </div>
                        )}
                      </Col>
                      <Col md={12}>
                        <Form.Item name={item.feesEquationId}>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))
                : null}
            </div>

            <div
              className={`error-text`}
              style={{
                opacity: hasError ? 1 : 0,
                transition: "0.2s all ease-in",
                color: "red",
                marginBlock: "1rem",
                fontSize: "1rem",
              }}
            >
              Please Fill All Fields.
            </div>
            <Form.Item className="tokens__button-wrapper">
              <Button
                // className="bap-primary-button bap-button"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={hasError || isDisabled}
              >
                Apply
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </section>
  );
};

export default FeesEquation;
