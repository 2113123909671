import { useState, useEffect } from "react";
import { Input, Button, Spin } from "antd";
import { getPrivacyPolice, postPrivacyPolice } from "../network";
import { toastError } from "helpers/toasters";
import { toastSuccess } from "./../../../../helpers/toasters";

const PrivicyAndPolicy = () => {
	const [privacyAndPolicy, setPrivacyAndPolicy] = useState(null);
	const [arPrivacyAndPolicy, setArPrivacyAndPolicy] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [dataLoading, setDataLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);

	useEffect(() => {
		if (arPrivacyAndPolicy && privacyAndPolicy) {
			setIsDisabled(false);
		} else setIsDisabled(true);
	}, [arPrivacyAndPolicy, privacyAndPolicy]);

	useEffect(() => {
		setDataLoading(true);
		getPrivacyPolice(
			(success) => {
				const {
					privacyPolicy: { en, ar },
				} = success.data;
				setDataLoading(false);
				setPrivacyAndPolicy(en);
				setArPrivacyAndPolicy(ar);
			},
			(fail) => {
				setDataLoading(false);
				if (fail.data.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, []);

	function addPrivacyAndPolicy() {
		setIsLoading(true);
		const payload = {
			en: privacyAndPolicy,
			ar: arPrivacyAndPolicy,
		};
		postPrivacyPolice(
			payload,
			(success) => {
				setIsLoading(false);
				toastSuccess(success.message);
			},
			(fail) => {
				setIsLoading(false);
				if (fail.data.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	return (
		<section className="main-section privacy">
			<header className="main-header">
				<h2>Privacy Policy</h2>
			</header>

			<div className="main-section__container box-shadow">
				{dataLoading && (
					<div className="spinner__container">
						<Spin className="spinner" />
					</div>
				)}

				<div className="privacy__container ">
					<Input.TextArea
						className="privacy__textarea"
						placeholder="Type privacy policy..."
						style={{
							height: 250,
						}}
						value={privacyAndPolicy}
						onChange={(e) => setPrivacyAndPolicy(e.target.value)}
					/>
					<Input.TextArea
						className="privacy__textarea"
						dir="rtl"
						placeholder="أكتب سياسة الخصوصية ..."
						style={{
							height: 250,
						}}
						value={arPrivacyAndPolicy}
						onChange={(e) => setArPrivacyAndPolicy(e.target.value)}
					/>

					<div className="privacy__btn-container">
						<Button
							type="primary"
							onClick={addPrivacyAndPolicy}
							disabled={isDisabled}
							loading={isLoading}
						>
							Submit
						</Button>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PrivicyAndPolicy;
