import axios from "axios";
// export const baseUrl = process.env.BASE_URL;
export const baseUrl = process.env.REACT_APP_BASE_URL;

let token = null;

export function getToken(newToken) {
	token = newToken;
}

export function getResource(path, onSuccess, onFail, reqAuth = true) {
	let requestData = {
		method: "get",
		url: baseUrl + path,
		headers: {},
	};

	if (reqAuth) {
		requestData.headers = {
			Authorization: "Bearer " + token,
		};
	}
	axios(requestData)
		.then((res) => {
			onSuccess(res.data);
		})
		.catch((fail) => {
			if (
				fail?.response?.status === 401 ||
				fail?.response?.status === 403 ||
				fail?.response?.status === 409
			) {
				window.location = "/logout";
			} else {
				onFail(fail.response);
			}
		});
}

export function postResource(
	path,
	data,
	onSuccess,
	onFail,
	reqAuth = true,
	multiPart = false
) {
	let requestData = {
		method: "post",
		url: baseUrl + path,
		headers: {},
		data,
	};

	if (token && reqAuth) {
		requestData.headers = {
			...requestData.headers,
			Authorization: "Bearer " + token,
		};
	}

	if (multiPart) {
		requestData.headers = {
			...requestData.headers,
			"content-type": "multipart/form-data",
		};
	}
	axios(requestData)
		.then((res) => {
			onSuccess(res.data);
		})
		.catch((fail) => {
			if (fail?.response?.status === 401 || fail?.response?.status === 403) {
				window.location = "/logout";
			} else {
				onFail(fail.response);
			}
		});
}

export function deleteResource(path, onSuccess, onFail, reqAuth = true) {
	let requestData = {
		method: "delete",
		url: baseUrl + path,
		headers: {},
	};

	if (token && reqAuth) {
		requestData.headers = {
			Authorization: "Bearer " + token,
		};
	}
	axios(requestData)
		.then((res) => {
			onSuccess(res.data);
		})
		.catch((fail) => {
			if (fail?.response?.status === 401 || fail?.response?.status === 403) {
				window.location = "/logout";
			} else {
				onFail(fail.response);
			}
		});
}

export function patchResource(
	path,
	data,
	onSuccess,
	onFail,
	reqAuth = true,
	multipart = false
) {
	let requestData = {
		method: "patch",
		url: baseUrl + path,
		headers: {},
		data,
	};

	if (token && reqAuth) {
		requestData.headers = {
			...requestData.headers,
			Authorization: "Bearer " + token,
		};
	}

	if (multipart) {
		requestData.headers = {
			...requestData.headers,
			"content-type": "multipart/form-data",
		};
	}

	axios(requestData)
		.then((res) => {
			onSuccess(res.data);
		})
		.catch((fail) => {
			if (fail?.response?.status === 401 || fail?.response?.status === 403) {
				window.location = "/logout";
			} else {
				onFail(fail.response);
			}
		});
}

export function putResource(
	path,
	data,
	onSuccess,
	onFail,
	reqAuth = true,
	multipart = false
) {
	let requestData = {
		method: "put",
		url: baseUrl + path,
		headers: {},
		data,
	};

	if (token && reqAuth) {
		requestData.headers = {
			...requestData.headers,
			Authorization: "Bearer " + token,
		};
	}

	if (multipart) {
		requestData.headers = {
			...requestData.headers,
			"content-type": "multipart/form-data",
		};
	}

	axios(requestData)
		.then((res) => {
			onSuccess(res.data);
		})
		.catch((fail) => {
			if (fail?.response?.status === 401 || fail?.response?.status === 403) {
				window.location = "/logout";
			} else {
				onFail(fail.response);
			}
		});
}
