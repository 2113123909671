import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import {
	postNumberOfBiddersForEveryService,
	postNumberOfBiddersForEveryArea,
	postNumberOfHiringsForEveryDayInLastMonth,
	getNumberOfHiringDividedByBidders,
	postNumberOfFeedbacksDividedByBidders,
	getNumberOfHiringDividedByShortlisting,
	postNumberOfProAccountsInEachDayInLastMonth,
} from "../network";
import {
	Chart as ChartJS,
	ArcElement,
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";

import { Bar, Doughnut, Line } from "react-chartjs-2";
import { toastError } from "helpers/toasters";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	ArcElement
);

const HiringPerformance = () => {
	const [labelsForBiddersForService, setLabelsForBiddersForService] = useState(
		[]
	);
	const [dataForBiddersForService, setDataForBiddersForService] = useState([]);

	const [labelBidderPerArea, setLabelBidderPerArea] = useState([]);
	const [dataBidderPerArea, setDataBidderPerArea] = useState([]);

	const [labelBiddersHiresInLastMonth, setLabelBiddersHiresInLastMonth] =
		useState([]);
	const [dataBiddersHiresInLastMonth, setDataBiddersHiresInLastMonth] =
		useState([]);

	const [labelAccountsCreated, setLabelAccountsCreated] = useState([]);
	const [dataAccountsCreated, setDataAccountsCreated] = useState([]);

	const [numberOfHiringPerBidders, setNumberOfHiringPerBidders] =
		useState(null);
	const [numberOfFeedbackPerBidders, setNumberOfFeedbackPerBidders] =
		useState(null);
	const [numberOfHiringPerShortlisting, setNumberOfHiringPerShortlisting] =
		useState(null);

	const status = "hire";

	useEffect(() => {
		postNumberOfBiddersForEveryService(
			status,
			(success) => {
				success.data.map((item) => {
					setLabelsForBiddersForService((prev) => [...prev, item.service]);
					setDataForBiddersForService((prev) => [...prev, item.count]);
					return item;
				});
			},
			(fail) => {
				if (fail?.data.error) {
					toastError(fail?.data.err_msg);
				} else {
					toastError();
				}
			}
		);
	}, []);

	useEffect(() => {
		postNumberOfBiddersForEveryArea(
			status,
			(success) => {
				success.data.forEach((item) => {
					setLabelBidderPerArea((prev) => [...prev, item.area]);
					setDataBidderPerArea((prev) => [...prev, item.count]);
				});
			},
			(fail) => {
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	}, []);

	useEffect(() => {
		postNumberOfHiringsForEveryDayInLastMonth(
			(success) => {
				success.data.forEach((item) => {
					setLabelBiddersHiresInLastMonth((prev) => [...prev, item.date]);
					setDataBiddersHiresInLastMonth((prev) => [...prev, item.count]);
				});
			},
			(fail) => {
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	}, []);

	useEffect(() => {
		getNumberOfHiringDividedByBidders(
			(success) => {
				setNumberOfHiringPerBidders(success.data);
			},
			(fail) => {
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	}, []);

	useEffect(() => {
		postNumberOfFeedbacksDividedByBidders(
			"hire",
			(success) => {
				setNumberOfFeedbackPerBidders(success.data);
			},
			(fail) => {
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, []);

	useEffect(() => {
		getNumberOfHiringDividedByShortlisting(
			(success) => {
				setNumberOfHiringPerShortlisting(success.data);
			},
			(fail) => {
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, []);

	useEffect(() => {
		postNumberOfProAccountsInEachDayInLastMonth(
			(success) => {
				success.data.forEach((item) => {
					setLabelAccountsCreated((prev) => [...prev, item.date]);
					setDataAccountsCreated((prev) => [...prev, item.count]);
				});
			},
			(fail) => {
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, []);

	function getBarsOptions(title) {
		const options = {
			responsive: true,
			scales: {
				yAxes: {
					grid: { display: false },
					title: {
						display: true,
						text: title,
						color: "#12819c",
						font: {
							size: 16,
						},
					},
				},
				xAxes: {
					grid: { display: false },
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					xAlign: "center",
					yAlign: "bottom",
				},
			},
		};
		return options;
	}

	const arcOptions = {
		plugins: {
			id: "custom plugin",
			legend: {
				display: false,
			},
			beforeAll: (chart) => {
				var width = chart.width,
					height = chart.height,
					ctx = chart.ctx;
				ctx.restore();
				var fontSize = (height / 160).toFixed(2);
				ctx.font = fontSize + "em sans-serif";
				ctx.textBaseline = "top";
				var text = "15",
					textX = Math.round((width - ctx.measureText(text).width) / 2),
					textY = height / 2;
				ctx.fillText(text, textX, textY);
				ctx.save();
			},
		},
	};

	const lineOption = {
		responsive: true,
		scales: {
			yAxes: {
				grid: {
					display: false,
					fraction: false,
				},
				beginAtZero: true,
				title: {
					display: true,
					text: "Number of hiring",
					color: "#12819c",
					font: {
						size: 16,
					},
				},
			},
			xAxes: {
				beginAtZero: true,
				grid: {
					display: false,
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				xAlign: "center",
				yAlign: "bottom",
			},
			LinearScale: false,
		},
	};

	const secondLineOption = {
		responsive: true,
		scales: {
			yAxes: {
				grid: {
					display: false,
					fraction: false,
				},
				ticks: {
					stepSize: 1,
				},
				beginAtZero: true,
				title: {
					display: true,
					text: "Number Of Accounts",
					color: "#12819c",
					font: {
						size: 16,
					},
				},
			},
			xAxes: {
				beginAtZero: true,
				grid: {
					display: false,
				},
				ticks: {
					stepSize: 1,
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				xAlign: "center",
				yAlign: "bottom",
			},
			LinearScale: false,
		},
	};

	const biddersForServiceDigramData = {
		labels: labelsForBiddersForService,
		datasets: [
			{
				data: dataForBiddersForService,
				backgroundColor: ["#12819C", "#2693AD", "#609FAF", "#609FAF"],
				barThickness: 35,
			},
		],
	};

	const biddersForAreaDigramData = {
		labels: labelBidderPerArea,
		datasets: [
			{
				data: dataBidderPerArea,
				backgroundColor: ["#12819C", "#2693AD", "#609FAF", "#609FAF"],
				barThickness: 35,
			},
		],
	};

	const biddersInLastMonthDigramData = {
		labels: [null, ...labelBiddersHiresInLastMonth],
		datasets: [
			{
				label: "Number Of Hiring",
				data: [null, ...dataBiddersHiresInLastMonth],
				tension: 0.35,
				borderColor: "#5FB4C9",
				pointBorderColor: "#12819C",
				pointBackgroundColor: "#12819C",
				position: "top center",
			},
		],
		options: {
			scales: {
				yAxes: {
					beginAtZero: true,
				},
			},
		},
	};

	const createdAccountDigramData = {
		labels: [null, ...labelAccountsCreated],
		datasets: [
			{
				label: "Number Of Accounts",
				data: [null, ...dataAccountsCreated],
				tension: 0.35,
				borderColor: "#5FB4C9",
				pointBorderColor: "#12819C",
				pointBackgroundColor: "#12819C",
				position: "top center",
			},
		],
		options: {
			scales: {
				yAxes: {
					beginAtZero: true,
					ticks: {
						stepSize: 1,
					},
				},
			},
		},
	};

	function getArcData([firstLable, secondLable], data) {
		let successData = +data?.split("%")[0] / 100;
		const arcData = {
			labels: [firstLable, secondLable],
			datasets: [
				{
					label: "Shortlisted Over Bids",
					data: data ? [successData, 1 - successData] : null,
					backgroundColor: ["#12819c", "rgba(250, 250, 250)"],
					weight: 0.5,
					cutout: "90%",
				},
			],
			cutoutPercentage: 90,
		};

		return arcData;
	}

	return (
		<section className="performance main-section">
			<header className="main-header">
				<h2>Hiring Performance</h2>
			</header>

			<Row className="performance__row box-shadow">
				<Col xs={24} md={12}>
					<div className="performance__chart">
						<Bar
							options={getBarsOptions("Number Of Hiring")}
							data={biddersForServiceDigramData}
						/>
						<div className="chart__title">Service Type Hiring</div>
					</div>
				</Col>

				<Col xs={24} md={12}>
					<div className="performance__chart">
						<Bar
							options={getBarsOptions("Number Of Hiring")}
							data={biddersForAreaDigramData}
						/>
						<div className="chart__title">
							Area Hiring(m<sup>2</sup>)
						</div>
					</div>
				</Col>
			</Row>

			<Row>
				<Col xs={24} md={24} className="performance__row  box-shadow">
					<div className="performance__chart">
						<Line options={lineOption} data={biddersInLastMonthDigramData} />
						<div className="chart__title">Overall Hiring Activity/ Month</div>
					</div>
				</Col>
			</Row>

			<Row className="performance__row  box-shadow">
				<Col xs={24} md={8}>
					<div className="performance__chart">
						<div className="chart__title">Hiring - Bids</div>
						<div className="chart__percent">{numberOfHiringPerBidders}</div>
						<Doughnut
							options={arcOptions}
							data={getArcData(["Hiring", "Bids"], numberOfHiringPerBidders)}
						/>
					</div>
				</Col>
				<Col xs={24} md={8}>
					<div className="performance__chart">
						<div className="chart__title">Feedback - Hiring</div>
						<div className="chart__percent">{numberOfFeedbackPerBidders}</div>
						<Doughnut
							options={arcOptions}
							data={getArcData(
								["Feedback", "Bids"],
								numberOfFeedbackPerBidders
							)}
						/>
					</div>
				</Col>
				<Col xs={24} md={8}>
					<div className="performance__chart">
						<div className="chart__title">Hiring - Shortlisting</div>
						<div className="chart__percent">
							{numberOfHiringPerShortlisting}
						</div>
						<Doughnut
							options={arcOptions}
							data={getArcData(
								["Shortlisting", "Bids"],
								numberOfHiringPerShortlisting
							)}
						/>
					</div>
				</Col>
			</Row>

			<Row>
				<Col xs={24} md={24} className="performance__row  box-shadow">
					<div className="performance__chart">
						<Line options={secondLineOption} data={createdAccountDigramData} />
						<div className="chart__title">Pros</div>
					</div>
				</Col>
			</Row>
		</section>
	);
};

export default HiringPerformance;
