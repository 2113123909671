import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import {
	getUtlDetails,
	getUtlsAndMore,
} from "modules/Pages/PagesModal/network";
import ProRowCard from "./ProRowCard";
import SquarePlus from "assets/Icons/square-plus-box.svg";
import AddUtilityDetail from "./AddUtilityDetail";
function ViewUtilities() {
	const [utilities, updateUtilities] = useState([]);
	const [activeKey, updateActiveKey] = useState(0);
	const [proList, updateProList] = useState([]);
	const [mode, updateMode] = useState("view");

	const getAllUtils = () => {
		getUtlsAndMore(
			(success) => {
				updateUtilities(success.data);
			},
			(fail) => {}
		);
	};
	useEffect(() => {
		getAllUtils();
	}, []);
	useEffect(() => {
		if (utilities.length > 0) {
			getUtilDetailsFunction();
		}
		// eslint-disable-next-line
	}, [utilities, activeKey]);
	const utilityNode = () => {
		return <></>;
	};
	const handleAddNewUtilDetail = () => {
		updateMode("add");
	};
	const getUtilDetailsFunction = () => {
		const utilId = utilities[activeKey]._id;
		let data = { utilId };
		getUtlDetails(
			data,
			(success) => {
				updateProList(success.data);
			},
			(fail) => {}
		);
	};
	return (
		<div className="utilitiesAndMore">
			<div className="d-flex align-items-start">
				<div className="d-flex flex-1 flex-column">
					<Tabs
						tabPosition={"top"}
						style={{
							maxHeight: "220px",
							overflow: "auto",
							width: "-webkit-fill-available",
						}}
						onTabClick={(key) => {
							updateActiveKey(key);
							updateMode("view");
						}}
						activeKey={activeKey}
						items={utilities?.map((utility, index) => {
							return {
								label: (
									<>
										<>{utility?.title?.en}</>
									</>
								),
								key: index,
								children: utilityNode,
							};
						})}
						className="d-flex flex-1"
					/>
					{mode === "view" ? (
						<div className="users-list">
							{proList.map((pro) => {
								return (
									<ProRowCard
										pro={pro}
										getUtilDetailsFunction={getUtilDetailsFunction}
									/>
								);
							})}
						</div>
					) : mode === "add" ? (
						<AddUtilityDetail
							currentUtilityId={utilities[activeKey]?._id}
							getMode={(value) => updateMode(value)}
						/>
					) : (
						<></>
					)}
				</div>
				<img
					src={SquarePlus}
					alt="SquarePlus"
					className="cursor-pointer mt-4"
					onClick={handleAddNewUtilDetail}
				/>
			</div>
		</div>
	);
}

export default ViewUtilities;
