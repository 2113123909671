import { useState, useEffect } from "react";
import { Select, Button, InputNumber } from "antd";
import {
	addServiceFile,
	getAllServices,
	uploadFile,
	addDrawingsRestrictions,
	getQuotation,
	getDrawingsRestrictions,
} from "../network";
import { toastError, toastSuccess } from "helpers/toasters";
import uploadImage from "assets/Icons/upload-white.svg";
import CustomTooltip from "modules/components/CustomTooltip";

const Quotations = () => {
	const [services, setServices] = useState(null);
	const [selectedService, setSelectedService] = useState(null);
	const [filePath, setFilePath] = useState(null);
	const [isUploading, setIsUploading] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState(null);
	const [isDisabled, setIsDisabled] = useState(true);
	const [submitServiceQuotationsLoading, setSubmitServiceQuotationsLoading] =
		useState(false);
	const [selectedServiceForDrawings, setSelectedServiceForDrawings] =
		useState(null);
	const [isDisabledFileSizeBtn, setIsDisabledFileSizeBtn] = useState(true);
	const [maxFileNumbers, setMaxFileNumbers] = useState(1);
	const [maxFileSize, setMaxFileSize] = useState(1);
	const [isLoadingFileSizeBtn, setIsLoadingFileSizeBtn] = useState(false);

	useEffect(() => {
		if (filePath && selectedService) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}

		if (selectedServiceForDrawings && maxFileNumbers && maxFileSize) {
			setIsDisabledFileSizeBtn(false);
		} else {
			setIsDisabledFileSizeBtn(true);
		}
	}, [
		filePath,
		selectedService,
		maxFileNumbers,
		maxFileSize,
		selectedServiceForDrawings,
	]);

	useEffect(() => {
		if (selectedService) {
			getQuotation(
				selectedService,
				(success) => {
					setUploadedFiles(success.data.quotationFilePath.split("Z")[1]);
				},
				(fail) => {
					if (fail?.data?.error) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		}
	}, [selectedService]);

	useEffect(() => {
		getAllServices(
			(success) => {
				setServices(success.data);
			},
			(fail) => {
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	}, []);

	useEffect(() => {
		if (selectedServiceForDrawings) {
			setIsDisabledFileSizeBtn(true);
			getDrawingsRestrictions(
				{ serviceId: selectedServiceForDrawings },
				(success) => {
					if (success.data) {
						const { ImageSize, numberOfFiles } = success.data;
						setMaxFileSize(ImageSize || 1);
						setMaxFileNumbers(numberOfFiles || 1);
					}
					setIsDisabledFileSizeBtn(false);
				},
				(fail) => {
					if (fail?.error?.data) {
						toastError(fail?.error?.data);
					} else {
						toastError();
					}
				}
			);
		}
	}, [selectedServiceForDrawings]);

	function handleChangeService(value) {
		setSelectedService(value);
	}

	function uploadServiceFile(e) {
		setIsUploading(true);

		let fd = new FormData();
		fd.append("quotationFileName", e.target.files[0]);

		setIsUploading(true);
		uploadFile(
			fd,
			(success) => {
				setIsUploading(false);
				setIsDisabled(false);
				setUploadedFiles(success.data.fileName.split("Z")[1]);
				setFilePath(success.data.path);
			},
			(fail) => {
				setIsUploading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	function submitSerivceQuoations() {
		if (selectedService && uploadedFiles) {
			setSubmitServiceQuotationsLoading(true);
			let payload = {
				serviceId: selectedService,
				quotationFilePath: filePath,
			};
			addServiceFile(
				payload,
				(success) => {
					setSubmitServiceQuotationsLoading(false);
					toastSuccess("File Added to the service Successfuly");
					setUploadedFiles(null);
					setFilePath(null);
				},
				(fail) => {
					setSubmitServiceQuotationsLoading(false);
					if (fail?.data?.error) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		}
	}

	function addFileRestriction() {
		if (selectedServiceForDrawings && maxFileNumbers && maxFileSize) {
			const payload = {
				serviceId: selectedServiceForDrawings,
				ImageSize: maxFileSize,
				numberOfFiles: maxFileNumbers,
			};
			setIsLoadingFileSizeBtn(true);
			addDrawingsRestrictions(
				payload,
				(success) => {
					setIsLoadingFileSizeBtn(false);
					toastSuccess("Data Added Successfully");
				},
				(fail) => {
					setIsLoadingFileSizeBtn(false);
					if (fail?.data?.error) {
						toastError(fail?.data.error);
					} else {
						toastError();
					}
				}
			);
		}
	}

	return (
		<section className="main-section quotaions">
			<header className="main-header">
				<h2>Quotations</h2>
			</header>
			<div className="quotaions__files box-shadow">
				<div className="quotaions__container">
					<Select
						placeholder="Service"
						className="quotaions__select"
						onChange={handleChangeService}
						options={services?.map(({ name, _id }) => ({
							label: name,
							value: _id,
						}))}
						dropdownMatchSelectWidth={400}
						style={{
							width: 250,
						}}
					/>
					<div className="quotaions__uploader">
						<Button type="primary" className="upload-btn" loading={isUploading}>
							<label
								role={"button"}
								className="stretched-link"
								htmlFor="upload"
							>
								<img src={uploadImage} alt="uploadImage" className="me-2" />
								Upload File
							</label>
							<input
								style={{
									display: "none",
								}}
								id="upload"
								type="file"
								onChange={uploadServiceFile}
							/>
						</Button>
					</div>
					<div
						className="name"
						style={{
							marginTop: ".5rem",
							fontSize: "1rem",
							color: "GrayText",
							visibility: uploadedFiles ? "visible" : "hidden",
							opacity: uploadedFiles ? 1 : 0,
						}}
					>
						<span>
							<CustomTooltip count={25} text={uploadedFiles || ""} />
						</span>
					</div>
				</div>

				<div className="quotaions__submit">
					<Button
						type="primary"
						disabled={isDisabled}
						onClick={submitSerivceQuoations}
						className="submit-btn"
						loading={submitServiceQuotationsLoading}
					>
						Submit
					</Button>
				</div>
			</div>

			<header className="main-header mt-2">
				<h2>Drawing Files</h2>
			</header>
			<div className="quotaions__sizes box-shadow">
				<Select
					placeholder="Service"
					className="quotaions__select"
					onChange={setSelectedServiceForDrawings}
					options={services?.map(({ name, _id }) => ({
						label: name,
						value: _id,
					}))}
					dropdownMatchSelectWidth={400}
					style={{
						width: 250,
						marginBottom: "1rem",
					}}
				/>
				<div className="quotaions__container">
					{
						<div className="size__item">
							<span>Maximum number of files</span>
							<InputNumber
								min={1}
								width={50}
								className="size__input"
								disabled
								value={maxFileNumbers}
								onChange={setMaxFileNumbers}
							/>
						</div>
					}

					<div className="size__item">
						<span>Maximum file size</span>
						<InputNumber
							accept="[0-9]"
							min={1}
							width={50}
							className="size__input"
							value={maxFileSize}
							onChange={setMaxFileSize}
						/>
						<strong>MB</strong>
					</div>
				</div>
				<div className="quotaions__submit">
					<Button
						type="primary"
						disabled={isDisabledFileSizeBtn}
						onClick={addFileRestriction}
						loading={isLoadingFileSizeBtn}
						className="submit-btn"
					>
						Submit
					</Button>
				</div>
			</div>
		</section>
	);
};

export default Quotations;
