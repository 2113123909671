import { createSearchParams } from "react-router-dom";

export const PATHS_PARAMS = {
  signin: "signin",
  numberOfBiddersForEveryService: "numberOfBiddersForEveryService",
  numberOfBiddersForEveryArea: "numberOfBiddersForEveryArea",
  numberOfBiddersForEverDayInLastMonth: "numberOfBiddersForEverDayInLastMonth",
  numberOfHiringDividedByBidders: "numberOfHiringDividedByBidders",
  numberOfFeedbacksDividedByBidders: "numberOfFeedbacksDividedByBidders",
  numberOfShortlistingDividedByBidders: "numberOfShortlistingDividedByBidders",
  numberOfProAccountsInEachDayInLastMonth:
    "numberOfProAccountsInEachDayInLastMonth",
  allClientProjectWithDetails: "allClientProjectWithDetails",
  biddersShortlistingQuotationsForSingleProject:
    "biddersShortlistingQuotationsForSingleProject",
  deleteClientProject: "deleteClientProject",
  allServices: "allServices",
  allStatus: "allStatus",
  allHomeOwners: "allHomeOwners",
  activateUser: "activateUser",
  allProsAccounts: "allProsAccounts",
  allAdmins: "allAdmins",
  addAdmin: "addAdmin",
  editAdmin: "editAdmin",
  deactivateAdmin: "deactivateAdmin",
  getFeesEquations: "getFeesEquations",
  editFeesEquations: "editFeesEquations",
  getProjectBerief: "getProjectBerief",
  assignProsToSpecificProject: "assignProsToSpecificProject",
  getTokenPackages: "getTokenPackages",
  editTokenPackage: "editTokenPackage",
  getTokenConversion: "getTokenConversion",
  editTokenConversion: "editTokenConversion",
  addTokenConversion: "addTokenConversion",
  getMonthlyAndAnnualTransaction: "getMonthlyAndAnnualTransaction",
  getServiceTypes: "getServiceTypes",
  uploadBannerImage: "uploadBannerImage",
  editBanner: "editBanner",
  getBanner: "getBanner",
  getHomeServiceBanner: "getHomeServiceBanner",
  uploadHomeServiceBannerImage: "uploadHomeServiceBannerImage",
  editHomeServiceBanner: "editHomeServiceBanner",
  deleteHomeServiceBanner: "deleteHomeServiceBanner",
  uploadUtlsAndMoreImage: "uploadUtlsAndMoreImage",
  getUtlsAndMore: "getUtlsAndMore",
  editUtls: "editUtls",
  deleteUtls: "deleteUtls",
  deactivateUtil: "deactivateUtil",
  addUtls: "addUtls",
  getHomeHowItWorks: "getHomeHowItWorks",
  uploadHowItWorksVideo: "uploadHowItWorksVideo",
  edithomeHowItWorks: "edithomeHowItWorks",
  getQAndA: "getQAndA",
  editQAndA: "editQAndA",
  deleteQAndA: "deleteQAndA",
  addQAndA: "addQAndA",
  deactiveteQandA: "deactiveteQandA",
  getBlogs: "getBlogs",
  uploadBlogBannerImage: "uploadBlogBannerImage",
  addBlog: "addBlog",
  editBlog: "editBlog",
  getArticle: "getArticle",
  blogCategories: "blogCategories",
  getRoles: "getRoles",
  sendArticlesViaEmail: "sendArticlesViaEmail",
  addArticle: "addArticle",
  addBlogCategory: "addBlogCategory",
  editBlogCategory: "editBlogCategory",
  deleteBlogCategory: "deleteBlogCategory",
  proPortofolio: "proPortofolio",
  approveProPortofolio: "approveProPortofolio",
  contactUs: "contactUs",
  editArticle: "editArticle",
  deleteArticle: "deleteArticle",
  getArticleById: "getArticleById",
  uploadCategoryImg: "uploadCategoryImg",
  editBlogArticle: "editArticle",
  addBlogArticle: "addArticle",
  getUtilDetails: "getUtilDetails",
  deleteUtilDetails: "deleteUtilDetails",
  getAllServices: "getAllServices",
  uploadQuotaionsFiles: "uploadQuotaionsFiles",
  addServiceQuotations: "addServiceQuotations",
  getDrawingsRestrictions: "getDrawingsRestrictions",
  getServiceQuestionsAndAnswers: "getServiceQuestionsAndAnswers",
  addServiceQuestion: "addServiceQuestion",
  editServiceQuestion: "editServiceQuestion",
  getAllQuestionsAndAnswers: "getAllQuestionsAndAnswers",
  addParagraphQuestions: "addParagraphQuestions",
  getQuestionByQuestionId: "getQuestionByQuestionId",
  deleteServiceQuestion: "deleteServiceQuestion",
  addServiceAnswer: "addServiceAnswer",
  editServiceAnswer: "editServiceAnswer",
  deleteServiceAnswer: "deleteServiceAnswer",
  uploadUtlContactImage: "uploadUtlContactImage",
  addUtilContact: "addUtilContact",
  getAllFeedbackUsers: "getAllFeedbackUsers",
  feedbackAnswers: "feedbackAnswers",
  userSuggesstion: "userSuggesstion",
  avarageFeedbacks: "avarageFeedbacks",
  sendTokenToPro: "sendTokenToPro",
  getTermsAndConditions: "getTermsAndConditions",
  getPrivacyPolice: "getPrivacyPolice",
  numberOfHiringDividedByShortlisting: "numberOfHiringDividedByShortlisting",
  numberOfHiringInLastMonth: "numberOfHiringInLastMonth",
  logoutAdmin: "logoutAdmin",
  getQuotations: "getQuotations",
  optionalServices: "optionalServices",
  userRoles: "userRoles",
  postNewBanner: "postNewBanner",
  postHomeServiceBanner: "postHomeServiceBanner",
  getProServices: "getProServices",
  getProRates: "getProRates",
  deleteProReview: "deleteProReview",
  verifyUser: "verifyUser",
  verifyAdmin: "verifyAdmin",
};

export const PATHS = (targetApi, params) => {
  let paths = {
    signin: "/api/admins/loginAdmin",
    numberOfBiddersForEveryService: "/api/adminPanel/biddersCountForServices",
    numberOfBiddersForEveryArea: "/api/adminPanel/biddersCountForAreas",
    numberOfBiddersForEverDayInLastMonth:
      "/api/adminPanel/biddersCountForEachDayOfMonth",
    numberOfHiringDividedByBidders:
      "/api/adminPanel/getNumberOfHiringDividedByBidders",
    numberOfFeedbacksDividedByBidders:
      "/api/adminPanel/getNumberOfFeedbacksDividedByBidders",
    numberOfShortlistingDividedByBidders:
      "/api/adminPanel/getNumberOfShortlistingDividedByBidders",
    numberOfProAccountsInEachDayInLastMonth:
      "/api/adminPanel/getNumberOfProAccountsInEachDayInLastMonth",
    allClientProjectWithDetails:
      "/api/adminPanel/getAllClientProjectWithDetails",
    biddersShortlistingQuotationsForSingleProject:
      "/api/adminPanel/getBiddersShortlistingAndQoutationForSingleProject",
    deleteClientProject: "/api/adminPanel/deleteClientProject",
    allServices: "/api/v1/services",
    allStatus: "/api/adminPanel/getStatusFilter",
    allHomeOwners: "/api/adminPanel/clients",
    activateUser: "/api/adminPanel/deactivateUser",
    allProsAccounts: "/api/adminPanel/getAllProAccounts",
    allAdmins: "/api/adminPanel/getAllAdmins",
    addAdmin: "/api/adminPanel/addAdmin",
    editAdmin: "/api/adminPanel/editAdmin",
    deactivateAdmin: "/api/adminPanel/deactivateAdmin",
    getFeesEquations: "/api/feesEquations/getFeesEquations",
    editFeesEquations: "/api/feesEquations/editFeesEquation",
    getProjectBerief: "/api/adminPanel/getProjectSubmittedBrief",
    assignProsToSpecificProject: "/api/adminPanel/assignProjectToPro",
    getTokenPackages: "/api/tokenPackages/getTokenPackages",
    editTokenPackage: "/api/tokenPackages/editTokenPackage",
    getTokenConversion: "/api/tokenConversion/getTokenConversion",
    editTokenConversion: "/api/tokenConversion/editTokenConversion",
    addTokenConversion: "/api/tokenPackages/addTokenPackage",
    getMonthlyAndAnnualTransaction:
      "/api/adminPanel/getMonthlyAndAnnualTransactions",
    getServiceTypes: "/api/v1/servicetype/serviceTypes",
    deactivateUtil: "/api/utilities/deactivateUtil",
    uploadUtlsAndMoreImage: "/api/utilities/uploadImage",
    getUtlsAndMore: "/api/utilities/getUtilities",
    editUtls: "/api/utilities/editUtility",
    addUtls: "/api/utilities/addUtility",
    deleteUtls: "/api/utilities/deleteUtil",
    addBanner: "/api/banners/addBanner",
    uploadBannerImage: "/api/banners/uploadImage",
    editBanner: "/api/banners/editBanner",
    getBanner: "/api/banners/getBanner",
    getHomeServiceBanner: "/api/homeServiceBanners/getHomeServiceBanner",
    uploadHomeServiceBannerImage: "/api/homeServicebanners/uploadImage",
    editHomeServiceBanner: "/api/homeServiceBanners/editHomeServiceBanner",
    deleteHomeServiceBanner: "/api/homeServiceBanners/deleteHomeServiceBanner",
    getHomeHowItWorks: "/api/homeHowItWorks/gethomeHowItWorks",
    uploadHowItWorksVideo: "/api/homeHowItWorks/uploadVideo",
    edithomeHowItWorks: "/api/homeHowItWorks/edithomeHowItWorks",
    getQAndA: "/api/FAQs/getFAQ",
    editQAndA: "/api/FAQs/editFAQ",
    deleteQAndA: "/api/FAQs/deleteFAQ",
    addQAndA: "/api/FAQs/addFAQ",
    deactiveteQandA: "/api/FAQs/deactivateFAQ",
    getBlogs: "/api/blog",
    uploadBlogBannerImage: "/api/blog/uploadImg",
    editBlog: "/api/blog",
    addBlog: "/api/blog",
    getArticle: "/api/articles/getArticle",
    blogCategories: "/api/blogCategories",
    getRoles: "/api/v1/getRoles",
    sendArticlesViaEmail: "/api/articles/sendEmail",
    addArticle: "/api/articles/addArticle",
    addBlogCategory: "/api/blogCategories",
    editBlogCategory: "/api/blogCategories",
    deleteBlogCategory: "/api/blogCategories",
    proPortofolio: "/api/adminPanel/getProPortfolio",
    approveProPortofolio: "/api/adminPanel/approveProPortofolio",
    contactUs: "/api/contactUs",
    editArticle: "/api/articles/editArticle",
    deleteArticle: "/api/articles/deleteArticle",
    getArticleById: "/api/articles/getArticleById",
    uploadCategoryImg: "/api/blog/uploadCategoryImg",
    editBlogArticle: "/api/articles/editArticle",
    addBlogArticle: "/api/articles/addArticle",
    getUtilDetails: "/api/utilDetails/getUtilDetails",
    deleteUtilDetails: "/api/utilDetails/deleteUtilDetails",
    getAllServices: "/api/v1/services",
    uploadQuotaionsFiles: "/api/serviceQuotations/uploadFile",
    addServiceQuotations: "/api/serviceQuotations",
    getDrawingsRestrictions: "/api/drawingsRestrictions",
    getServiceQuestionsAndAnswers: "/api/v1/servicesQuestions",
    addParagraphQuestions: "/api/paragraphQuestions",
    getAllQuestionsAndAnswers: "/api/adminPanel/getAllQuestions",
    getQuestionByQuestionId: "/api/adminPanel/getQuestionById",
    addServiceQuestion: "/api/v1/newServiceQuestion",
    editServiceQuestion: "/api/v1/editServiceQuestion",
    deleteServiceQuestion: "/api/v1/deleteServiceQuestion",
    addServiceAnswer: "/api/v1/newServiceAnswers",
    editServiceAnswer: "/api/v1/editServiceAnswer",
    deleteServiceAnswer: "/api/v1/deleteServiceAnswer",
    uploadUtlContactImage: "/api/utilities/uploadImage",
    addUtilContact: "/api/utilDetails/addUtilDetails",
    getAllFeedbackUsers: "/api/feedbackAnswers/users",
    avarageFeedbacks: "/api/feedbackAnswers/average",
    feedbackAnswers: "/api/feedbackAnswers",
    userSuggesstion: "/api/suggestions/user",
    sendTokenToPro: "/api/adminPanel/sendTokens",
    getTermsAndConditions: "/api/termsAndConditions",
    getPrivacyPolice: "/api/privacyPolicy",
    numberOfHiringDividedByShortlisting:
      "/api/adminPanel/getNumberOfHiringsDividedByShortlisting",
    numberOfHiringInLastMonth: "/api/adminPanel/hiringsCountForEachDayOfMonth",
    logoutAdmin: "/api/admins/isActive",
    getQuotations: "/api/adminPanel/getServiceQuotation",
    optionalServices: "/api/optionalServices/admin",
    userRoles: "/api/roles",
    postNewBanner: "/api/banners/addBanner",
    postHomeServiceBanner: "/api/homeServiceBanners/addHomeServiceBanner",
    getProServices: "/api/adminPanel/getProService",
    getProRates: "/api/adminPanel/getProRates",
    deleteProReview: "/api/adminPanel/deleteProReview",
    verifyUser: "/api/adminPanel/verifyUser",
    verifyAdmin: "/api/adminPanel/verifyAdmin",
  };

  let url;
  if (params) url = paths[targetApi] + "?" + createSearchParams(params);
  else url = paths[targetApi];
  return url;
};
