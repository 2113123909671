import { Modal } from "antd";

/**
 *  Projects Modal => Show all projects name
 *
 */
const ProjectsModal = ({ userName, projects, visible, onCancel }) => {
  return (
    <Modal
      className="custom-modal projects-modal"
      visible={visible}
      onCancel={onCancel}
      centered
      footer={false}
    >
      <div className="custom-modal__text">{userName}</div>
      <ul className="projects-modal__ul">
        {projects?.length
          ? projects.map(({ name, _id }) => <li key={_id}>{name}</li>)
          : null}
      </ul>
    </Modal>
  );
};

export default ProjectsModal;
