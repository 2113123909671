import { useState } from "react";
import { Modal, Button } from "antd";
import { deleteProRate } from "modules/Pages/Dashboard/network";
import { toastSuccess, toastError } from "helpers/toasters";

export const DeleteRatingModal = ({
	onCancel,
	deleteModalVisibility,
	proId,
	projectId,
	setData,
}) => {
	const [isLoading, setIsLoading] = useState(false);

	function confirmDelete() {
		setIsLoading(true);
		deleteProRate(
			proId,
			projectId,
			(success) => {
				setIsLoading(false);
				toastSuccess(success.message);
				setData((prev) =>
					prev.map((item) => {
						if (item.projectId === projectId) item.comment = null;
						return item;
					})
				);
				onCancel();
			},
			(fail) => {
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
					setIsLoading(false);
				} else {
					toastError();
					setIsLoading(false);
				}
			}
		);
	}

	return (
		<Modal
			className="custom-modal"
			onCancel={onCancel}
			visible={deleteModalVisibility}
			footer={null}
			centered>
			<div>
				<p className="custom-modal__text">
					Are you sure that you want to delete this review?
				</p>
				<p>It will be deleted permanently from your website</p>
			</div>

			<div className="custom-modal__controllers">
				<Button
					loading={isLoading}
					className="danger-button"
					onClick={confirmDelete}>
					Delete
				</Button>
				<Button className="secondary-button" onClick={onCancel}>
					cancel
				</Button>
			</div>
		</Modal>
	);
};
