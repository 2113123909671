import { useEffect } from "react";
import { isActiveUser } from "./network";

const IsActiveAdmin = () => {
	useEffect(() => {
		isActiveUser(
			() => {},
			(fail) => {
				if (fail?.data) {
					console.log("User Not Active");
				}
			}
		);
	});

	return <></>;
};

export default IsActiveAdmin;
