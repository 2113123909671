import { useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import TokenIcon from "assets/Icons/tokens.svg";
import pageIcon from "assets/Icons/page.svg";
import dashboardIcon from "assets/Icons/dashboard.svg";
import templateIcon from "assets/Icons/template.svg";
import inboxIcon from "assets/Icons/inbox.svg";
import transactionIcon from "assets/Icons/transaction.svg";
import statusIcon from "assets/Icons/project status.svg";
import ROUTES from "constants/ROUTES";
import { isAdmin, isFinanceAdmin } from "helpers/adminRole";
import { map } from "lodash";

const Sidebar = () => {
	const { pathname } = useLocation();
	const {
		authorization: { serivces, roleName },
	} = useSelector((state) => state.auth);

	let data = [
		{
			...(isAdmin(roleName) && {
				pages: {
					name: "Pages",
					eventKey: ROUTES.PAGES,
					icon: pageIcon,
					children: [
						{
							name: "Landing Page",
							link: ROUTES.PAGES + "/" + ROUTES.PAGES_LANDING,
						},
						{
							name: "Blog",
							link: ROUTES.PAGES + "/" + ROUTES.BLOGS,
						},
						{
							name: "Utilities & More",
							link: ROUTES.PAGES + "/" + ROUTES.PAGES_UTILITIES,
						},
					],
				},
			}),

			...(isAdmin(roleName) && {
				dashboard: {
					name: "Dashboard",
					eventKey: ROUTES.DASHBOARD,
					icon: dashboardIcon,
					children: [
						{
							name: "Projects",
							link: ROUTES.DASHBOARD + "/" + ROUTES.PROJECTS,
						},
						{
							name: "Property Owners",
							link: ROUTES.DASHBOARD + "/" + ROUTES.PROPERTY_OWNER,
						},
						{
							name: "Pros",
							link: ROUTES.DASHBOARD + "/" + ROUTES.PROS,
						},
					],
				},
			}),

			...(isAdmin(roleName) && {
				templates: {
					name: "Templates",
					eventKey: ROUTES.TEMPLATES,
					icon: templateIcon,
					children: [
						{
							name: "Quotations & Drawings",
							link: ROUTES.TEMPLATES + "/" + ROUTES.QUOTATIONS,
						},
						{
							name: "Terms And Conditions",
							link: ROUTES.TEMPLATES + "/" + ROUTES.TERMSANDCONDITIONS,
						},
						{
							name: "Privacy Policy",
							link: ROUTES.TEMPLATES + "/" + ROUTES.PRIVACYANDPOLICY,
						},
					],
				},
			}),
			...(isFinanceAdmin(roleName) && {
				tokens: {
					name: "Tokens",
					eventKey: ROUTES.TOKENS,
					icon: TokenIcon,
					children: [
						{
							name: "Fees Equation",
							link: ROUTES.TOKENS + "/" + ROUTES.FEESE_QUATION,
						},
						{
							name: "Token Conversion",
							link: ROUTES.TOKENS + "/" + ROUTES.TOKEN_CONVERSION,
						},
						{
							name: "Token Packages",
							link: ROUTES.TOKENS + "/" + ROUTES.TOKEN_PACKAGES,
						},
					],
				},
			}),

			...(isFinanceAdmin(roleName) && {
				transactions: {
					name: "Transactions",
					eventKey: ROUTES.TRANSACTIONS,
					icon: transactionIcon,
					children: [
						{
							name: "Monthly",
							link: ROUTES.TRANSACTIONS + "/" + ROUTES.MONTHLY,
						},
						{
							name: "Annual",
							link: ROUTES.TRANSACTIONS + "/" + ROUTES.ANNUALY,
						},
					],
				},
			}),

			...(isFinanceAdmin(roleName) && {
				projectStats: {
					name: "Project's Stats",
					eventKey: ROUTES.PROJECT_STATS,
					icon: statusIcon,
					children: [
						{
							name: "Bidding Performance",
							link: ROUTES.PROJECT_STATS + "/" + ROUTES.BIDDING_PERFORMANCE,
						},
						{
							name: "Hiring Performance",
							link: ROUTES.PROJECT_STATS + "/" + ROUTES.HIRING_PERFORMANCE,
						},
					],
				},
			}),
			...(isAdmin(roleName) && {
				inbox: {
					name: "Inbox",
					eventKey: ROUTES.INBOX,
					icon: inboxIcon,
					children: [
						{
							name: "Contact Us",
							link: ROUTES.INBOX + "/" + ROUTES.CONTACT_US,
						},
						{
							name: "Feedback",
							link: ROUTES.INBOX + "/" + ROUTES.FEEDBACK,
						},
					],
				},
			}),
		},
	];

	if (isAdmin(roleName)) {
		serivces?.forEach((item, _idx) => {
			data[0].templates.children.splice(_idx, 0, {
				name: item.name,
				link: ROUTES.TEMPLATES + "/" + ROUTES.SERVICE + `/${item._id}`,
			});
		});
	}

	if (roleName === "Super admin") {
		data[0]?.dashboard.children.push({
			name: "Admins",
			link: ROUTES.DASHBOARD + "/" + ROUTES.SUPER_ADMIN,
		});
	}

	return (
		<div className="sidebar">
			{map(data[0], (item) => (
				<Accordion key={item.name} defaultActiveKey={pathname.split("/")[2]}>
					<Accordion.Item key={item.name} eventKey={item.name}>
						<Accordion.Header>
							<img src={item.icon} alt={item.name} />
							<span>{item.name}</span>
						</Accordion.Header>
						<Accordion.Body>
							{item.children.map((child, _) => (
								<NavLink
									to={child.link}
									key={_}
									style={({ isActive }) => {
										return {
											textDecoration: isActive && "underline",
										};
									}}
									className="sidebar__child"
								>
									<div>{child.name}</div>
								</NavLink>
							))}
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			))}
		</div>
	);
};

export default Sidebar;
