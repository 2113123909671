import { useState, useEffect } from "react";
import { Form, Input, Button, Radio } from "antd";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import UploadIcon from "assets/Icons/upload.svg";
import {
	editHomeServiceBanner,
	getHomeServiceBanner,
	uploadHomeServiceBannerImage,
	deleteHomeServiceBanner,
} from "modules/Pages/PagesModal/network";
import TextArea from "antd/lib/input/TextArea";
import { baseUrl } from "services";
import { toastError, toastSuccess } from "helpers/toasters";

function LandingHomeServices() {
	const [arabicMedia, updateArabicMedia] = useState(null);
	const [englishMedia, updateEnglishMedia] = useState(null);
	const [arabicTitle, setArabicTitle] = useState("");
	const [englishTitle, setEnglishTitle] = useState("");
	const [arabicDescription, setArabicDescription] = useState("");
	const [arabicDescriptionMobile, setArabicDescriptionMobile] = useState("");
	const [englishDescription, setEnglishDescription] = useState("");
	const [englishDescriptionMobile, setEnglishDescriptionMobile] = useState("");
	const [isVideo, updateIsVideo] = useState(null);
	const [loadingDelete, updateLoadingDelete] = useState(false);
	const [loadingEdit, updateLoadingEdit] = useState(false);
	const [enButton, setEnButton] = useState("");
	const [arButton, setArButton] = useState("");
	const [mediaFormatAlert, updateMediaFormatAlert] = useState(false);
	const [loadingMedia, updateLoadingMedia] = useState({ en: false, ar: false });
	const [allData, updateAllData] = useState(null);
	const [sliderIndex, updateSliderIndex] = useState(0);
	const [indexList, updateIndexList] = useState([]);
	const [sortValue, setSortValue] = useState(1);
	const { t } = useTranslation();

	const getAllBanners = () => {
		getHomeServiceBanner(
			(success) => {
				let data = success.data[sliderIndex];
				let englishMedia = {};
				let arabicMedia = {};
				englishMedia.path = data.image.en;
				arabicMedia.path = data.image.ar;
				if (data.image.en.includes("-image-")) {
					updateIsVideo(false);
					englishMedia.name = data.image.en.split("-image-")[1];
					arabicMedia.name = data.image.ar.split("-image-")[1];
				} else {
					updateIsVideo(true);
					englishMedia.name = data.image.en.split("-video-")[1];
					arabicMedia.name = data.image.ar.split("-video-")[1];
				}

				setEnglishTitle(data.title.en);
				setArabicTitle(data.title.ar);
				setEnglishDescription(data.websiteText.en);
				setArabicDescription(data.websiteText.ar);
				setEnglishDescriptionMobile(data.mobileText.en);
				setArabicDescriptionMobile(data.mobileText.ar);
				updateEnglishMedia(englishMedia);
				updateArabicMedia(arabicMedia);
				updateIndexList(success.data);
				setEnButton(data.button.en);
				setArButton(data.button.ar);
				setSortValue(data.index);
				updateAllData(data);
			},
			(fail) => {}
		);
	};

	useEffect(() => {
		getAllBanners();
		// eslint-disable-next-line
	}, [sliderIndex]);
	const handleUploadBannerImage = (e) => {
		const isImageOrVideo =
			e.target.files[0].type.includes("image") ||
			e.target.files[0].type.includes("video");

		const isBothHaveDiffFormat =
			isVideo !== null &&
			((isVideo && e.target.files[0].type.includes("image")) ||
				(!isVideo && e.target.files[0].type.includes("video")));
		if (!isImageOrVideo) {
			updateMediaFormatAlert({
				place: e.target.id,
				msg: "* Please, upload image or video files only",
			});
		} else {
			if (isBothHaveDiffFormat) {
				updateArabicMedia(null);
				updateEnglishMedia(null);
			}
			updateMediaFormatAlert(null);
			if (e.target.id === "arBannerFile") {
				let loadingMediaVar = { ...loadingMedia };
				loadingMediaVar.ar = true;
				updateLoadingMedia(loadingMediaVar);
			} else {
				let loadingMediaVar = { ...loadingMedia };
				loadingMediaVar.en = true;
				updateLoadingMedia(loadingMediaVar);
			}
			let imageDataFile = new FormData();
			imageDataFile.append(
				"image",
				e.target.files[0],
				`-${e.target.files[0].type.split("/")[0]}-${e.target.files[0].name}`
			);
			uploadHomeServiceBannerImage(
				imageDataFile,
				(success) => {
					if (success.isSuccess) {
						updateIsVideo(e.target.files[0].type.includes("video"));

						if (e.target.id === "arBannerFile") {
							let fileData = Object.assign(e.target.files[0]);
							fileData.path = success.data.path;
							updateArabicMedia(fileData);
						} else {
							let fileData = Object.assign(e.target.files[0]);
							fileData.path = success.data.path;
							updateEnglishMedia(fileData);
						}
					}
					let loadingMediaVar = { ...loadingMedia };
					loadingMediaVar.en = false;
					loadingMediaVar.ar = false;
					updateLoadingMedia(loadingMediaVar);
				},
				(fail) => {
					let loadingMediaVar = { ...loadingMedia };
					loadingMediaVar.en = false;
					loadingMediaVar.ar = false;
					updateLoadingMedia(loadingMediaVar);
				}
			);
		}
	};
	const deleteServiceBanner = () => {
		let data = { bannerId: allData._id };
		updateLoadingDelete(true);

		deleteHomeServiceBanner(
			data,
			(success) => {
				updateLoadingDelete(false);
				toastSuccess(success.message);
				getAllBanners();
			},
			(fail) => {
				updateLoadingDelete(false);

				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	};
	const onFinish = (values) => {
		let data = {
			...values,
			arMedia: arabicMedia.path,
			enMedia: englishMedia.path,
		};
		let modifiedData = {
			"title.en": data.enTitle,
			"title.ar": data.arTitle,
			"websiteText.en": data.enDescription,
			"websiteText.ar": data.arDescription,
			"mobileText.en": data.englishDescriptionMobile,
			"mobileText.ar": data.arabicDescriptionMobile,
			"image.en": data.enMedia,
			"image.ar": data.arMedia,
			"button.en": data.enButton,
			"button.ar": data.arButton,
			isVideo,
			index: sortValue,
			bannerId: allData._id,
		};

		updateLoadingEdit(true);
		editHomeServiceBanner(
			modifiedData,
			(success) => {
				updateLoadingEdit(false);
				toastSuccess(success.message);
			},
			(fail) => {
				updateLoadingEdit(false);
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	};

	const onRadioChange = (e) => {
		setSortValue(e.target.value);
	};

	return (
		<div className="home_services">
			<div className="d-flex justify-content-between">
				<header className="header">
					<h6>{t("landingPageHomeServices")}</h6>
				</header>
				<Dropdown>
					<Dropdown.Toggle variant="success" id="dropdown-basic">
						Index
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Radio.Group onChange={onRadioChange} value={sortValue}>
							{indexList.map((indexItem, indexNumber) => {
								return (
									<Dropdown.Item key={indexNumber}>
										<Radio value={indexNumber + 1}>
											<div
												onClick={() => {
													updateSliderIndex(indexNumber);
												}}
												className={
													sliderIndex === indexNumber && "text-underline"
												}
											>
												{indexNumber + 1}
											</div>
										</Radio>
									</Dropdown.Item>
								);
							})}
						</Radio.Group>
					</Dropdown.Menu>
				</Dropdown>
			</div>
			<Form
				onFinish={onFinish}
				name="basic"
				initialValues={{
					enTitle: englishTitle,
					arTitle: arabicTitle,
					enDescription: englishDescription,
					arDescription: arabicDescription,
					englishDescriptionMobile,
					arabicDescriptionMobile,
					enButton,
					arButton,
				}}
				layout="vertical"
				key={allData?._id}
			>
				<Form.Item
					name="enMedia"
					colon={false}
					valuePropName={englishMedia}
					required
					labelWrap={true}
					rules={[
						{
							required: true,
							validator: (_, value) => {
								if (englishMedia) {
									return Promise.resolve();
								} else {
									return Promise.reject(
										new Error("Please, upload the english media")
									);
								}
							},
						},
					]}
				>
					<div className="bap-image-show d-flex align-items-center my-2">
						<a
							href={baseUrl + "/" + englishMedia?.path}
							target="_blank"
							without
							rel="noreferrer"
						>
							{englishMedia?.name}
						</a>
					</div>
					<input
						type="file"
						id="enBannerFile"
						className="d-none"
						onChange={handleUploadBannerImage}
					/>

					<Button
						loading={loadingMedia.en}
						className="bap-outline-button cursor-pointer m-0 p-0"
					>
						<label htmlFor="enBannerFile" className="cursor-pointer">
							<span>{t("uploadEnImage")}</span>
							<img src={UploadIcon} alt="upload" />
						</label>
					</Button>
					{mediaFormatAlert?.place === "enBannerFile" && (
						<div className="mx-2 text-danger">{mediaFormatAlert.msg}</div>
					)}
				</Form.Item>
				<Form.Item
					name="arMedia"
					colon={false}
					required
					requiredMark={false}
					rules={[
						{
							required: true,
							validator: (_, value) => {
								if (arabicMedia) {
									return Promise.resolve();
								} else {
									return Promise.reject(
										new Error("Please, upload the arabic media")
									);
								}
							},
						},
					]}
				>
					<div className="bap-image-show d-flex align-items-center my-2">
						<a
							href={baseUrl + "/" + arabicMedia?.path}
							target="_blank"
							without
							rel="noreferrer"
						>
							{arabicMedia?.name}
						</a>
					</div>

					<input
						type="file"
						id="arBannerFile"
						className="d-none"
						onChange={handleUploadBannerImage}
					/>

					<div className="d-flex justify-content-end">
						<Button
							loading={loadingMedia.ar}
							className="bap-outline-button cursor-pointer m-0 p-0"
						>
							<label htmlFor="arBannerFile" className="cursor-pointer">
								<span>{t("uploadArImage")}</span>
								<img src={UploadIcon} alt="upload" />
							</label>
						</Button>
					</div>
					{mediaFormatAlert?.place === "arBannerFile" && (
						<div className="mx-2 text-danger">{mediaFormatAlert.msg}</div>
					)}
				</Form.Item>
				<Form.Item
					name="enTitle"
					colon={false}
					required
					requiredMark={false}
					label="Banner title for laptop"
					rules={[
						{
							required: true,
							message: "Please, enter the english title",
						},
					]}
				>
					<Input
						className="bap-input"
						placeholder={"Banner Title For Laptop"}
						value={englishTitle}
						onChange={(e) => setEnglishTitle(e.target.value)}
						maxLength={60}
					/>
				</Form.Item>
				<Form.Item
					name="arTitle"
					colon={false}
					required
					label="عنوان البانر لشاشة اللاب توب"
					requiredMark={false}
					rules={[
						{
							required: true,
							message: "Please, enter the arabic title",
						},
					]}
				>
					<Input
						style={{
							direction: "rtl",
						}}
						className="bap-input"
						value={arabicTitle}
						onChange={(e) => setArabicTitle(e.target.value)}
						maxLength={60}
						placeholder={"عنوان البانر لشاشه اللاب توب"}
					/>
				</Form.Item>
				<Form.Item
					name="enDescription"
					colon={false}
					required
					label="Banner text for laptop"
					requiredMark={false}
					rules={[
						{
							required: true,
							message: "Please, enter the english description",
						},
					]}
				>
					<TextArea
						className="bap-textarea"
						value={englishDescription}
						onChange={(e) => setEnglishDescription(e.target.value)}
						placeholder={"Banner Text For Laptop"}
						maxLength={250}
					/>
				</Form.Item>
				<Form.Item
					name="arDescription"
					colon={false}
					required
					requiredMark={false}
					label="محتوى البانر لشاشة اللاب توب"
					rules={[
						{
							required: true,
							message: "Please, enter the arabic description",
						},
					]}
				>
					<TextArea
						className="bap-textarea"
						value={arabicDescription}
						onChange={(e) => setArabicDescription(e.target.value)}
						placeholder={"محتوي البانر لشاشه اللاب توب"}
						maxLength={250}
					/>
				</Form.Item>
				<Form.Item
					name="englishDescriptionMobile"
					colon={false}
					required
					label="Mobile banner text "
					requiredMark={false}
					rules={[
						{
							required: true,
							message: "Please, enter the english description for mobile",
						},
					]}
				>
					<TextArea
						className="bap-textarea"
						value={englishDescriptionMobile}
						onChange={(e) => setEnglishDescriptionMobile(e.target.value)}
						placeholder={"Mobile Banner Text"}
						maxLength={90}
					/>
				</Form.Item>
				<Form.Item
					name="arabicDescriptionMobile"
					colon={false}
					required
					label="محتوى البانر لشاشة الموبايل"
					requiredMark={false}
					rules={[
						{
							required: true,
							message: "Please, enter the arabic description for mobile",
						},
					]}
				>
					<TextArea
						className="bap-textarea"
						value={arabicDescriptionMobile}
						onChange={(e) => setArabicDescriptionMobile(e.target.value)}
						placeholder={"محتوي البانر لشاشه الموبايل"}
						maxLength={90}
					/>
				</Form.Item>
				<Form.Item
					name="enButton"
					colon={false}
					required
					label="English button"
					requiredMark={false}
					rules={[
						{
							required: true,
							message: "Please, enter the english button",
						},
					]}
				>
					<Input
						className="bap-input"
						placeholder={"English button"}
						value={enButton}
						onChange={(e) => setEnButton(e.target.value)}
						maxLength={30}
					/>
				</Form.Item>
				<Form.Item
					name="arButton"
					colon={false}
					required
					label="Arabic button"
					requiredMark={false}
					rules={[
						{
							required: true,
							message: "Please, enter the arabic button",
						},
					]}
				>
					<Input
						className="bap-input"
						placeholder={"Arabic button"}
						value={arButton}
						onChange={(e) => setArButton(e.target.value)}
						maxLength={30}
					/>
				</Form.Item>

				<Form.Item>
					<div className="controllings-Buttons">
						<Button
							htmlType="submit"
							type="primary"
							className="bap-primary-button bap-button"
							loading={loadingEdit}
						>
							{t("edit")}
						</Button>
						<Button
							onClick={deleteServiceBanner}
							className="bap-primary-button bap-button"
							loading={loadingDelete}
							disabled={indexList.length <= 1}
						>
							{t("delete")}
						</Button>
					</div>
				</Form.Item>
			</Form>
		</div>
	);
}

export default LandingHomeServices;
