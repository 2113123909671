import { useState, useEffect } from "react";
import { Row, Col, Spin } from "antd";
import { getTokensPackages } from "../network";
import coinsIcon from "assets/Icons/coins.svg";
import addIcon from "assets/Icons/add.svg";
import CustomTooltip from "modules/components/CustomTooltip";
import AddEditPackageModal from "../components/AddEditPackage";
import { toastError } from "helpers/toasters";

const TokenPackages = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [isVisibleAddEditModal, setIsVisibleAddEditModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getTokensPackages(
      (success) => {
        setIsLoading(false);
        setPackages(success.data);
      },
      (fail) => {
        setIsLoading(false);
        if (fail?.data?.error) {
          toastError(fail.data.error);
        } else {
          toastError();
        }
      }
    );
  }, []);

  function addEditPackageModal(packageData) {
    setIsVisibleAddEditModal(true);
    setSelectedPackage(packageData);
  }

  return (
    <>
      <header className="main-header">
        <h2>Token Packages</h2>
      </header>

      <section
        className="packages main-section with__spinner"
        style={{
          display: packages?.length ? "block" : "flex",
        }}
      >
        <img
          className="add-package"
          src={addIcon}
          alt="add-package"
          onClick={() => addEditPackageModal(false)}
          role="button"
        />

        {!isLoading && packages?.length ? (
          <Row gutter={[32, 32]}>
            {packages.map((item, index) => (
              <Col md={6} key={index}>
                <div
                  className="packages__card box-shadow"
                  style={{
                    borderColor: item.isActive
                      ? "rgb(18,129,156, 1)"
                      : "rgb(18,129,156, 0.5)",
                  }}
                >
                  <div
                    className="edit-package"
                    role={"button"}
                    onClick={() => addEditPackageModal(item)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M8.424 12.282l4.402 4.399-5.826 1.319 1.424-5.718zm15.576-6.748l-9.689 9.804-4.536-4.536 9.689-9.802 4.536 4.534zm-6 8.916v6.55h-16v-12h6.743l1.978-2h-10.721v16h20v-10.573l-2 2.023z" />
                    </svg>
                  </div>

                  <div
                    style={{
                      opacity: item.isActive ? "1" : "0.55",
                      textAlign: "center",
                    }}
                  >
                    <div className="packages__title">
                      <p>
                        <CustomTooltip count={6} text={`${item.tokens}`} />
                      </p>
                      <figure className="packages__fig">
                        <img src={coinsIcon} alt="coins" />
                      </figure>
                    </div>
                    <div className="packages__name">
                      <CustomTooltip count={20} text={item.name} />
                    </div>
                    <div className="packages__price">
                      {item.price} <span>EGY</span>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        ) : !isLoading & !packages?.length ? (
          <div className="no-data">There are no packages here</div>
        ) : null}
      </section>
      {isVisibleAddEditModal && (
        <AddEditPackageModal
          isModalVisible={isVisibleAddEditModal}
          onCancel={() => setIsVisibleAddEditModal(false)}
          packageData={selectedPackage}
          setAllPackages={setPackages}
        />
      )}
    </>
  );
};

export default TokenPackages;
