import React, { useState, useEffect } from "react";
import { Form, Button, Col, Input, Row, Radio } from "antd";
import {
	addBlogArticle,
	editBlogArticle,
	getArticle,
	getArticleById,
	getBlogCategories,
	uploadCategoryImg,
} from "modules/Pages/PagesModal/network";
import Dropdown from "react-bootstrap/Dropdown";
import UploadIcon from "assets/Icons/upload.svg";
import Cross from "assets/Icons/cross.svg";
import { baseUrl } from "services";
import TextArea from "antd/lib/input/TextArea";
import { toastError, toastSuccess } from "helpers/toasters";

function BlogArticleDetails({ selectedArticle, clearSelectedArticle }) {
	const [name, updateName] = useState("");
	const [categoryOptions, updateCategoryOptions] = useState([]);
	const [categoryId, updateCategoryId] = useState("");
	const [index, updateIndex] = useState(null);
	const [indexOptions, updateIndexOptions] = useState([]);
	const [imageDetails, updateImageDetails] = useState([]);
	const [loading, updateLoading] = useState({});
	const [error, updateError] = useState({});
	const [description, updateDescription] = useState("");
	const [isCoveredIndex, updateIsCoveredIndex] = useState(0);

	useEffect(() => {
		if (categoryId) {
			let data = { categoryId };
			getArticle(
				data,
				(success) => {
					let indexList = [];
					success.data.forEach((item, index) => {
						indexList.push(index + 1);
					});
					const isNew = selectedArticle?.name;
					if (isNew) {
						indexList.push(success.data.length + 1);
					}

					updateIndexOptions(indexList);
				},
				(fail) => {}
			);
		}
		// eslint-disable-next-line
	}, [categoryId]);

	useEffect(() => {
		if (selectedArticle) {
			const isNew = selectedArticle?.name;
			if (isNew) {
				updateName(selectedArticle?.name);
				if (indexOptions.length) {
					updateIndexOptions((prev) => [...prev, prev[prev.length - 1] + 1]);
				} else {
					updateIndexOptions((prev) => [1]);
				}
			} else {
				let data = { articleId: selectedArticle };
				getArticleById(
					data,
					(success) => {
						const { data } = success;
						updateName(data.article.title);
						updateCategoryId(data.categoryId._id);
						updateIndex(data.index + 1);
						updateImageDetails(data.article.imageDetails);
						updateDescription(data.article.description);

						data.article.imageDetails.forEach((detail, index) => {
							if (detail.isCovered) {
								updateIsCoveredIndex(index);
							}
						});
					},
					(fail) => {}
				);
			}
		}
	}, [selectedArticle]);

	useEffect(() => {
		getBlogCategories(
			(success) => {
				const { data } = success;
				let allCategories = [];
				data.forEach((category) => {
					allCategories.push({ label: category.category, value: category._id });
				});
				updateCategoryOptions(allCategories);
			},
			(fail) => {}
		);
	}, []);

	const onFinish = () => {
		const isNew = selectedArticle?.name;
		let data = {
			articlesId: selectedArticle,
			categoryId,
			isActive: true,
			article: {
				imageDetails,
				title: name,
				description,
			},
			index: index - 1,
		};

		let hasCoverImage = imageDetails.filter((item) => item?.isCovered);
		if (!hasCoverImage.length) {
			data.article.imageDetails[0].isCovered = true;
		}
		updateLoading({ ...loading, save: true });
		if (isNew) {
			addBlogArticle(
				data,
				(success) => {
					updateLoading({ ...loading, save: false });
					toastSuccess(success.message);
					clearSelectedArticle();
				},
				(fail) => {
					updateLoading({ ...loading, save: false });
					if (fail?.data?.error) {
						toastError(fail?.data?.error);
					} else {
						toastError();
					}
				}
			);
		} else {
			editBlogArticle(
				data,
				(success) => {
					updateLoading({ ...loading, save: false });
					toastSuccess(success.message);
					clearSelectedArticle();
				},
				(fail) => {
					updateLoading({ ...loading, save: false });
					if (fail?.data?.error) {
						toastError(fail?.data?.error);
					} else {
						toastError();
					}
				}
			);
		}
	};

	const handleRemoveImage = (img) => {
		let imageDetailsList = [...imageDetails];
		imageDetailsList = imageDetailsList.filter(
			(imageDetail) => imageDetail !== img
		);
		updateImageDetails(imageDetailsList);
	};

	const handleUploadArticleImage = (e) => {
		const file = e.target.files[0];
		const isImage = file.type.includes("image");
		if (isImage) {
			let errorObject = { ...error };
			errorObject.uploadImage = false;
			updateError(errorObject);
			updateLoading({ uploadImage: true });
			const imageFile = new FormData();
			imageFile.append("imgPath", file);
			uploadCategoryImg(
				imageFile,
				(success) => {
					const path = success.data[0].path;
					updateImageDetails((prevValue) => [
						...prevValue,
						{
							imagePath: path,
							isCovered: false,
						},
					]);
					updateLoading({ uploadImage: false });
				},
				(fail) => {
					updateLoading({ uploadImage: false });
				}
			);
		} else {
			let errorObject = { ...error };
			errorObject.uploadImage = true;
			updateError(errorObject);
		}
	};

	const handleCoverChange = (e) => {
		let imageDetailsList = [...imageDetails];
		imageDetailsList.forEach((imageDetail) => {
			imageDetail.isCovered = false;
		});
		imageDetailsList[e.target.value].isCovered = true;
	};

	return (
		<div className="blog-article-details py-4 my-4">
			<Form onFinish={onFinish} name="basic" initialValues={{}}>
				<div className="d-flex justify-content-between align-items-center main-actions">
					<Form.Item
						name="name"
						style={{ width: 349 }}
						colon={false}
						valuePropName={name}
						required
						requiredMark={false}
						rules={[
							{
								required: true,
								validator: (_, value) => {
									if (name) {
										return Promise.resolve();
									} else {
										return Promise.reject(
											new Error("Please, fill the article name")
										);
									}
								},
							},
						]}
					>
						<Input
							value={name}
							onChange={(e) => {
								updateName(e.target.value);
							}}
							className="bap-input"
							type="text"
							placeholder="Article Name"
						/>
					</Form.Item>
					<Form.Item
						name="categoryId"
						colon={false}
						valuePropName={name}
						required
						requiredMark={false}
						rules={[
							{
								required: true,
								validator: (_, value) => {
									if (categoryId) {
										return Promise.resolve();
									} else {
										return Promise.reject(
											new Error("Please, Choose the category")
										);
									}
								},
							},
						]}
					>
						<Dropdown>
							<Dropdown.Toggle className="primary-dropdown" id="dropdown-basic">
								<span className="pe-4">Category</span>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{categoryOptions.map((category, index) => {
									return (
										<Dropdown.Item
											onClick={() => {
												updateCategoryId(category.value);
												updateIndex(null);
											}}
											className={
												category.value === categoryId
													? "text-underline cursor-pointer"
													: "cursor-pointer"
											}
											key={index}
										>
											{category.label}
										</Dropdown.Item>
									);
								})}
							</Dropdown.Menu>
						</Dropdown>
					</Form.Item>
					<Form.Item
						name="index"
						colon={false}
						valuePropName={index}
						required
						requiredMark={false}
						rules={[
							{
								required: true,
								validator: (_, value) => {
									if (index) {
										return Promise.resolve();
									} else {
										return Promise.reject(
											new Error("Please, choose the index")
										);
									}
								},
							},
						]}
					>
						<Dropdown>
							<Dropdown.Toggle className="primary-dropdown" id="dropdown-basic">
								Index
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{indexOptions.map((itemIndex) => {
									return (
										<Dropdown.Item
											onClick={() => {
												updateIndex(itemIndex);
											}}
											key={itemIndex}
											className={
												itemIndex === index
													? "text-underline cursor-pointer"
													: "cursor-pointer"
											}
											disabled={!categoryId}
										>
											{itemIndex}
										</Dropdown.Item>
									);
								})}
							</Dropdown.Menu>
						</Dropdown>
					</Form.Item>
				</div>
				<Form.Item
					name="imageDetails"
					colon={false}
					key={error.uploadImage}
					valuePropName={imageDetails}
					required
					requiredMark={false}
					rules={[
						{
							required: true,
							validator: (_, value) => {
								if (imageDetails.length) {
									return Promise.resolve();
								} else {
									return Promise.reject(
										new Error("Please, upload at least one image")
									);
								}
							},
						},
					]}
				>
					<Row>
						<Radio.Group
							defaultValue={isCoveredIndex}
							onChange={handleCoverChange}
						>
							{imageDetails?.map((img, index) => {
								return (
									<Col xs={12} md={8} key={index} className="my-2">
										<figure className="image-detail-figure">
											<img
												src={Cross}
												alt="Cross"
												onClick={() => {
													handleRemoveImage(img);
												}}
												className="cross-icon"
											/>
											<img
												src={baseUrl + "/" + img.imagePath}
												alt="imageDetails"
												className="image-detail"
											/>
											<Radio defaultChecked={img.isCovered} value={index}>
												Make Album Cover
											</Radio>
										</figure>
									</Col>
								);
							})}
						</Radio.Group>
					</Row>
					<input
						type="file"
						id="articleImages"
						className="d-none"
						onChange={handleUploadArticleImage}
					/>

					<Button
						loading={loading?.uploadImage}
						className="bap-outline-button cursor-pointer"
					>
						<label htmlFor="articleImages" className="cursor-pointer">
							<span>Upload Images</span>
							<img src={UploadIcon} alt="upload" />
						</label>
					</Button>
					{error?.uploadImage && (
						<div className="mx-2 text-danger">Please, upload images only</div>
					)}
				</Form.Item>
				<Form.Item
					name="description"
					colon={false}
					valuePropName={description}
					required
					requiredMark={false}
					rules={[
						{
							required: true,
							validator: (_, value) => {
								if (description) {
									return Promise.resolve();
								} else {
									return Promise.reject(
										new Error("Please, fill the article text")
									);
								}
							},
						},
					]}
				>
					<TextArea
						value={description}
						placeholder="Article Text"
						onChange={(e) => updateDescription(e.target.value)}
						className="article-text"
					/>
				</Form.Item>
				<Form.Item>
					<div className="text-center">
						<Button
							htmlType="submit"
							type="primary"
							className="bap-primary-button bap-button"
							loading={loading?.save}
						>
							Save
						</Button>
					</div>
				</Form.Item>
			</Form>
		</div>
	);
}

export default BlogArticleDetails;
