import { useState } from "react";
import { Button, Input, Modal, Form } from "antd";
import { useEffect } from "react";

function AddNewModal({ visible, onCancel, addDeleteDetails }) {
  const [name, updateName] = useState("");

  useEffect(() => {
    if (addDeleteDetails?.selected) {
      updateName(addDeleteDetails.selected.category);
    }
  }, [addDeleteDetails]);

  const onFinish = async () => {
    await addDeleteDetails?.saveFunction(
      addDeleteDetails.status === "new" ? { name } : name,
      addDeleteDetails?.selected?._id
    );
    updateName("");
    onCancel();
  };

  return (
    <Modal
      className="add-new-modal"
      visible={visible}
      onCancel={() => {
        updateName("");
        onCancel();
      }}
      footer={false}
    >
      <div className="title">{addDeleteDetails?.title}</div>
      <Form onFinish={onFinish} name="basic">
        <Form.Item
          className="my-4"
          name="name"
          colon={false}
          valuePropName={name}
          required
          requiredMark={false}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (name) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(new Error("Please type the name!"));
                }
              },
            },
          ]}
        >
          <Input
            type="text"
            value={name}
            onChange={(e) => updateName(e.target.value)}
            className="rounded-input my-2"
          />
        </Form.Item>
        <div className="text-center">
          <Button
            className="bap-primary-button bap-button"
            htmlType="submit"
            type="primary"
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default AddNewModal;
