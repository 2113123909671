import {
	getResource,
	deleteResource,
	putResource,
	postResource,
} from "services";

import { PATHS, PATHS_PARAMS } from "constants/PATHS";

export function getAllClientProjectDetailsWithFiltered(
	{ status, fromFilterDate, toFilterDate },
	onSuccess,
	onFail
) {
	let data = {
		status,
		fromFilterDate,
		toFilterDate,
	};

	const path = PATHS(PATHS_PARAMS.allClientProjectWithDetails);
	postResource(path, data, onSuccess, onFail);
}
export function postBiddersShortlistedQuotationsForSingleProject(
	{ projectId, status },
	onSuccess,
	onFail
) {
	const data = {
		projectId,
		status,
	};
	const path = PATHS(
		PATHS_PARAMS.biddersShortlistingQuotationsForSingleProject
	);
	postResource(path, data, onSuccess, onFail);
}

export function deleteClientProject({ projectId }, onSuccess, onFail) {
	const params = {
		projectId,
	};
	const path = PATHS(PATHS_PARAMS.deleteClientProject, params);
	deleteResource(path, onSuccess, onFail);
}

export function getAllService(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.allServices);
	getResource(path, onSuccess, onFail);
}

export function getAllStatus(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.allStatus);
	getResource(path, onSuccess, onFail);
}

export function postAllHomeOwner({ fromDate, toDate }, onSuccess, onFail) {
	const data = { fromDate, toDate };
	const path = PATHS(PATHS_PARAMS.allHomeOwners);
	postResource(path, data, onSuccess, onFail);
}

export function postActivateUser({ isActive, userId }, onSuccess, onFail) {
	const data = {
		isActive,
		userId,
	};
	const path = PATHS(PATHS_PARAMS.activateUser);
	postResource(path, data, onSuccess, onFail);
}

export function postActivateAdmin({ isActive, adminId }, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.deactivateAdmin);
	const data = { isActive, adminId };
	postResource(path, data, onSuccess, onFail);
}

export function postVerifyUser({ isEmailVerified, userId }, onSuccess, onFail) {
	const data = {
		isEmailVerified,
		userId,
	};
	const path = PATHS(PATHS_PARAMS.verifyUser);
	try{
	postResource(path, data, onSuccess, onFail);
}catch(e){
	console.log(e)
}
}

export function postVerifyAdmin({ isEmailVerified, userId }, onSuccess, onFail) {
	const data = {
		isEmailVerified,
		userId,
	};
	const path = PATHS(PATHS_PARAMS.verifyAdmin);
	postResource(path, data, onSuccess, onFail);
}


export function getAllAdmins(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.allAdmins);
	getResource(path, onSuccess, onFail);
}

export function postAllProsAccountsWithDetails(
	projectId,
	{ fromDate, toDate },
	onSuccess,
	onFail
) {
	const data = { fromDate, toDate, ...(projectId && { projectId }) };
	// if (projectId)
	let path = PATHS(PATHS_PARAMS.allProsAccounts);
	postResource(path, data, onSuccess, onFail);
}

export function addNewAdmin(
	{ userName, name, email, password, roleId, isActive },
	onSuccess,
	onFail
) {
	const data = {
		userName,
		name,
		email,
		password,
		roleId,
		isActive,
	};
	const path = PATHS(PATHS_PARAMS.addAdmin);
	postResource(path, data, onSuccess, onFail);
}

export function editAdmin(
	adminId,
	{ userName, name, email, password, roleId, isActive },
	onSuccess,
	onFail
) {
	const data = {
		userName,
		name,
		email,
		password,
		roleId,
		isActive,
	};
	const path = PATHS(PATHS_PARAMS.editAdmin, { adminId });
	putResource(path, data, onSuccess, onFail);
}

export function getProjectBrief(projectId, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getProjectBerief) + "/" + projectId;
	getResource(path, onSuccess, onFail);
}

export function assignProsToSpecificProject(
	pros,
	projectId,
	onSuccess,
	onFail
) {
	const path = PATHS(PATHS_PARAMS.assignProsToSpecificProject);
	let proIds = pros.map((item) => ({ id: item }));
	const data = {
		proIds,
		projectId,
	};
	postResource(path, data, onSuccess, onFail);
}

export function getProPortofolio({ proId }, onSuccess, onFail) {
	const data = { proId };
	const path = PATHS(PATHS_PARAMS.proPortofolio, data);
	getResource(path, onSuccess, onFail);
}

export function approveProPortofolio(
	{ projectId, approvals, regectionMessage },
	onSuccess,
	onFail
) {
	const data = { projectId, approvals, regectionMessage };
	const path = PATHS(PATHS_PARAMS.approveProPortofolio);
	postResource(path, data, onSuccess, onFail);
}

export function sendTokenToProByAdmin({ proId, tokens }, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.sendTokenToPro);
	const data = {
		proId,
		tokens,
	};
	postResource(path, data, onSuccess, onFail);
}

export function getOptionalServices(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.optionalServices);
	getResource(path, onSuccess, onFail);
}

export function getAllUserRoles(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.userRoles);
	getResource(path, onSuccess, onFail);
}

export function getProServiceTypePreferances(proId, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getProServices, { id: proId });
	getResource(path, onSuccess, onFail);
}

export function getProRating(proId, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getProRates, { id: proId });
	getResource(path, onSuccess, onFail);
}

export function deleteProRate(proId, projectId, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.deleteProReview, { proId, projectId });
	deleteResource(path, onSuccess, onFail);
}
