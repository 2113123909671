import { useState, useEffect } from "react";
import { Modal, Spin } from "antd";
import { getProServiceTypePreferances } from "modules/Pages/Dashboard/network";
import { toastError } from "helpers/toasters";

export const ServiceTypePreferancesModal = ({ isVisible, onCancel, proId }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [proData, setProData] = useState(null);

	useEffect(() => {
		setIsLoading(true);
		getProServiceTypePreferances(
			proId,
			(success) => {
				setIsLoading(false);
				setProData(success.data[0]);
			},
			(fail) => {
				setIsLoading(false);
				if (fail.data) {
					toastError(fail.data?.error);
				} else {
					toastError();
				}
			}
		);
	}, [proId]);

	function handleCancel() {
		onCancel();
	}

	return (
		<>
			<Modal
				className="proService-modal"
				visible={isVisible}
				onCancel={handleCancel}
				footer={false}
				width={"75vw"}
				centered
			>
				{isLoading ? (
					<Spin className="portofolio__spin" />
				) : (
					<div>
						<div className="proService-modal__profileData">
							<div className="d-flex justify-content-between mt-4">
								<div className="text">{proData?.proName}</div>
								<div className="text">{proData?.proEmail}</div>
								<div className="text">{proData?.proPhone}</div>
								<div className="text">{proData?.proWebsite}</div>
							</div>
							<div>
								{proData?.proBio && (
									<div className="bio text">Bio:{proData?.proBio}</div>
								)}
								<div className="text">{proData?.proAddress}</div>
							</div>
						</div>
						<div className="proService-modal__services-container">
							<div className="proService-modal__services">
								{proData?.serviceQA?.length ? (
									<>
										<div className="service">{proData.service}</div>
										<div className="service-type">{proData.serviceType}</div>
										<div className="service-type-items">
											{proData?.serviceTypeItems.map((item, idx) => (
												<div className="item" key={idx}>
													{item.name}
												</div>
											))}
										</div>
										<div className="service-type-other">
											{proData.serviceTypeOther}
										</div>
										<div className="questions">
											{proData?.serviceQA.map((item) => (
												<div className="questions__part">
													{item?.answers?.length ||
													item?.theOtherAnswer?.length ? (
														<>
															<div className="question">{item.question}</div>
															<div className="answer">
																{item.answers.map(({ answer }, idx) => (
																	<div key={idx} className="answer">
																		{answer}
																	</div>
																))}
																{item?.theOtherAnswer && (
																	<div className="answer">
																		{item.theOtherAnswer}
																	</div>
																)}
															</div>
														</>
													) : (
														<></>
													)}
												</div>
											))}
										</div>
									</>
								) : (
									<div className="empty-contianer">
										<div className="empty">
											There’s no service type preference yet.
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</Modal>
		</>
	);
};
