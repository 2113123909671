import React, { useState } from "react";
import ViewUtilities from "./Components/ViewUtilities";
const UtilitiesMore = () => {
  return (
    <div>
      <ViewUtilities />
    </div>
  );
};

export default UtilitiesMore;
