import { Button, Modal } from "antd";

function DeleteModal({ visible, onCancel, addDeleteDetails }) {
  const handleConfirm = async () => {
    if (addDeleteDetails.saveFunction) {
      await addDeleteDetails?.saveFunction(
        addDeleteDetails?.selected?._id,
        addDeleteDetails?.selected?.isActive
      );
      onCancel();
    } else if (addDeleteDetails?.deleteFunction) {
      await addDeleteDetails.deleteFunction();
      onCancel();
    }
  };

  return (
    <Modal
      className="delete-modal"
      visible={visible}
      onCancel={() => {
        onCancel();
      }}
      footer={false}
    >
      <div className="my-4" style={addDeleteDetails?.messageStyle}>
        {addDeleteDetails?.message}
      </div>
      <div className="text-center">
        <Button
          className="bap-primary-button bap-button"
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
}

export default DeleteModal;
