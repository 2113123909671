import searchIcon from "assets/Icons/search.svg";

const SearchInTable = ({ searchVal, setSearchVal, onSubmit, className }) => {
  function searchOnTable(e) {
    e.preventDefault();
    onSubmit();
  }

  return (
    <div className={`search-table ${className && className}`}>
      <form className="search-table__form" onSubmit={searchOnTable}>
        <input
          className="search-table__input"
          type="text"
          value={searchVal}
          onChange={(e) => setSearchVal(e.target.value)}
          onKeyUp={(e) => searchOnTable(e)}
          placeholder={"Search for"}
        />
        <img
          className="search-table__img"
          src={searchIcon}
          alt="search-in-table"
        />
      </form>
    </div>
  );
};

export default SearchInTable;
