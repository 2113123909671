import { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "store/authorization";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Form, Input, Row, Col, Checkbox, Button } from "antd";
import { signInApi } from "./network";
import { toastError } from "helpers/toasters";
import BidsterLogo from "assets/Images/logo.svg";
import { getAllServices } from "modules/Pages/Templates/network";
import { getUserRoles } from "modules/Shared/network";
import { getToken } from "services";

const Signin = () => {
	const [keepSign, setKeepSign] = useState(true);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	function changeKeepSignIn(checked) {
		setKeepSign(true);
	}

	async function onFinishForm(values) {
		const { email, password } = values;
		setLoading(true);

		signInApi(
			{ email: email.trim(), password: password.trim() },
			(success) => {
				let { data } = success;
				getToken(data.token);
				getAllServices(
					(serviceSuccess) => {
						getUserRoles(
							(success) => {
								setLoading(false);
								const { data: roles } = success;
								let USER_ROLE = roles.filter(
									(role) => role._id === data.roleId
								);
								dispatch(
									login({
										...data,
										serivces: serviceSuccess.data,
										roleStatus: USER_ROLE[0].status,
										roleName: USER_ROLE[0].name,
									})
								);
							},
							(fail) => {
								setLoading(false);
								if (fail.data.error) {
									toastError(fail.data.error);
								} else {
									toastError();
								}
							}
						);
					},
					(fail) => {
						setLoading(false);
						if (fail?.data?.error) {
							toastError(fail.data.error);
						} else {
							toastError();
						}
					}
				);
			},
			(fail) => {
				setLoading(false);
				if (fail?.data?.err_msg) {
					toastError(fail?.data?.err_msg);
				} else {
					toastError();
				}
			}
		);
	}

	return (
		<>
			<Helmet>
				<title>BAP | SignIn</title>
			</Helmet>

			<main className="signin">
				<div className="signin__modal">
					<header>
						<div className="signin__logo">
							<Link to="/">
								<img src={BidsterLogo} alt="bidster" />
							</Link>
						</div>
						<h2>SIGN IN</h2>
					</header>

					<Form
						className="signin__form"
						layout="vertical"
						onFinish={onFinishForm}
					>
						<Row gutter={[56, 0]} className="signin__inputs">
							<Col xs={24} md={12}>
								<Form.Item
									name="email"
									label="User Name/ Email*"
									colon={false}
									required
									requiredMark={false}
									rules={[
										{
											required: true,
											message: "Please, enter your email/username",
										},
									]}
								>
									<Input placeholder="User Name/ Email" />
								</Form.Item>
							</Col>
							<Col xs={24} md={12} className="">
								<Form.Item
									name="password"
									label="Password*"
									required
									requiredMark={false}
									rules={[
										{ required: true, message: "Please, enter your password" },
									]}
								>
									<Input.Password
										className="input-password"
										placeholder="password"
									/>
								</Form.Item>
							</Col>
						</Row>
						<div className="signin__keep">
							<Checkbox defaultChecked={keepSign} onChange={changeKeepSignIn}>
								Keep me signed in
							</Checkbox>
						</div>
						<Form.Item className="signin__submit">
							<Button type="primary" htmlType="submit" loading={loading}>
								Sign In
							</Button>
						</Form.Item>
					</Form>
				</div>
			</main>
		</>
	);
};

export default Signin;
