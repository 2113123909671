import { useEffect, useState } from "react";
import coinsIcon from "assets/Icons/coins.svg";
import { Form, Input, Button } from "antd";
import { getTokenConversion, editTokenConversion } from "../network";
import { toastError, toastSuccess } from "helpers/toasters";
const TokenConversion = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    getTokenConversion(
      (success) => {
        setIsDisabled(false);
        form.setFieldsValue({
          cost: success.data.cost,
        });
      },
      (fail) => {
        setIsDisabled(false);
        if (fail?.data?.error) {
          toastError(fail.data.errror);
        } else {
          toastError();
        }
      }
    );
  }, [form]);

  function submit(values) {
    const { cost } = values;
    setIsLoading(true);
    if (cost) {
      setHasError(false);
      editTokenConversion(
        { cost },
        (success) => {
          setIsLoading(false);
          toastSuccess(success.message);
        },
        (fail) => {
          setIsLoading(false);
          if (fail?.error?.data) {
            toastError(fail.error.data);
          } else {
            toastError();
          }
        }
      );
    } else {
      setHasError(true);
    }
  }

  function onValuesChange(value) {
    if (value.cost) {
      setHasError(false);
    } else {
      setHasError(true);
    }
  }

  return (
    <>
      <header className="main-header">
        <h2>Token Conversion</h2>
      </header>
      <section className="conversion main-section">
        <Form
          onFinish={submit}
          className="conversion__form box-shadow"
          form={form}
          onValuesChange={onValuesChange}
        >
          <div className="conversion__card ">
            <div className="conversion__text">
              1
              <figure className="conversion__fig">
                <img src={coinsIcon} alt="coins-icon" />
              </figure>
            </div>

            <div className="conversion__text">=</div>

            <Form.Item name={"cost"}>
              <Input type="number" min={0} />
            </Form.Item>
            <div className="conversion__text">EGY</div>
          </div>
          <div
            style={{
              color: "red",
              fontSize: "1rem",
              textAlign: "center",
              marginTop: "1rem",
              opacity: hasError ? 1 : 0,
              transition: "0.3sec ease-in-out",
            }}
          >
            This field is required
          </div>
          <Form.Item className="tokens__button-wrapper">
            <Button
              className="bap-primary-button bap-button"
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={hasError || isDisabled}
            >
              Apply
            </Button>
          </Form.Item>
        </Form>
      </section>
    </>
  );
};

export default TokenConversion;
