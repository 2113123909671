import { Modal, Spin } from "antd";
import { toastError } from "helpers/toasters";
import { useState, useEffect } from "react";
import { getProPortofolio } from "../network";
import Masonry from "react-masonry-css";
import ImagesApprovalModal from "./ImagesApprovalModal";
import { baseUrl } from "services";
import moment from "moment/moment";

const ProPortofolioModal = ({ isVisible, onCancel, proId, getAllPros }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [proProjects, setProProjects] = useState([]);
	const [isOpenProProject, setIsOpenProProject] = useState(false);
	const [selectedProject, setSelectedProject] = useState(null);
	const [withRender, setWithRender] = useState(false);

	// masonry-breakpoint-objects
	const breakpointColumnsObj = {
		default: 2,
		1100: 2,
		500: 1,
	};

	useEffect(() => {
		setIsLoading(true);
		getProPortofolio(
			{ proId },
			(success) => {
				setIsLoading(false);
				setProProjects(success.data);
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, [proId, withRender]);

	function handleCancel() {
		if (withRender) {
			getAllPros();
		}
		onCancel();
	}

	function handleOpenProject(project) {
		setIsOpenProProject(true);
		setSelectedProject(project);
	}

	return (
		<>
			<Modal
				className="portofolio"
				visible={isVisible}
				onCancel={handleCancel}
				footer={false}
				width={"75vw"}
				centered
			>
				{isLoading ? (
					<Spin className="portofolio__spin" />
				) : (
					<Masonry
						breakpointCols={breakpointColumnsObj}
						className="my-masonry-grid"
						columnClassName="my-masonry-grid_column"
					>
						{!isLoading &&
							proProjects?.map((project) => {
								let coveredImage = project.imageDetails.filter((item) => {
									if (!item.isImgApproved) {
									}
									return item.isCovered;
								})[0]?.imagePath;
								return (
									<div className="project" key={project._id}>
										{project.isReviewed && project.isProjectApproved ? (
											<div className={"approval approval-sign"} />
										) : (
											<div
												className={"approval approval-sign"}
												style={{ backgroundColor: "red" }}
											/>
										)}
										{!project.isReviewed && (
											<div className="approval approval-text">
												{project.pendingImgsCount}{" "}
												{10 >= project?.pendingImgsCount &&
												project?.pendingImgsCount > 2
													? "Files Pending"
													: "File Pending"}
											</div>
										)}

										<figure
											className="project__fig"
											onClick={() => handleOpenProject(project)}
											role={"button"}
										>
											{coveredImage ? (
												<img
													src={baseUrl + "/" + coveredImage}
													alt={project.projectName}
												/>
											) : (
												<img
													src={
														baseUrl + "/" + project.imageDetails[0].imagePath
													}
													alt={project.projectName}
												/>
											)}
										</figure>
										<div className="project__subtitle">
											{project.service} |{" "}
											{moment(project.createdAt).format("L")}
										</div>
										<h6
											className="project__title"
											onClick={() => handleOpenProject(project)}
											role={"button"}
										>
											{project.projectName}
										</h6>
									</div>
								);
							})}
					</Masonry>
				)}
			</Modal>
			{isOpenProProject && (
				<ImagesApprovalModal
					isVisible={isOpenProProject}
					onCancel={() => setIsOpenProProject(false)}
					project={selectedProject}
					rerenderProsOnCancel={setWithRender}
					setProProjects={setProProjects}
				/>
			)}
		</>
	);
};

export default ProPortofolioModal;
