import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Collapse } from "antd";
import { login } from "store/authorization";
import WebsiteTitle from "helpers/WebsiteTitle";
import { useTranslation } from "react-i18next";
import LandingBanner from "./components/LandingBanner";
import LandingHomeServices from "./components/LandingHomeServices";
import LandingPageUtilitiesAndMore from "./components/LandingPageUtilitiesAndMore";
import LandingHowItWorks from "./components/LandingHowItWorks";
import QAndA from "./components/QAndA";
const LandingPage = () => {
	const dispatch = useDispatch();
	const { state } = useLocation();
	const { Panel } = Collapse;
	const { t } = useTranslation();

	useEffect(() => {
		if (state?.user) {
			dispatch(login(state?.user));
		}
	}, [state?.user, dispatch]);
	const onAccordionChange = (key) => {};
	return (
		<>
			<WebsiteTitle title={"Pages - Landing Page"} />
			<section className="main-section pages landing">
				<header className="main-header">
					<h2 className="main-title">{t("landingPage")}</h2>
				</header>
				<Collapse onChange={onAccordionChange}>
					<Panel
						header={
							<div className="accordionTitle">{t("landingPageBanner")}</div>
						}
						key="1">
						<LandingBanner />
					</Panel>
					<Panel
						header={
							<div className="accordionTitle">
								{t("landingPageHomeServices")}
							</div>
						}
						key="2">
						<LandingHomeServices />
					</Panel>
					<Panel
						header={
							<div className="accordionTitle">
								{t("landingPageUtilitiesAndMore")}
							</div>
						}
						key="3">
						<LandingPageUtilitiesAndMore />
					</Panel>
					<Panel
						header={
							<div className="accordionTitle">{t("landingPageHowIt")}</div>
						}
						key="4">
						<LandingHowItWorks />
					</Panel>
					<Panel
						header={<div className="accordionTitle">{t("qAndA")}</div>}
						key="5">
						<QAndA />
					</Panel>
				</Collapse>
			</section>
		</>
	);
};

export default LandingPage;
