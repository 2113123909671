import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { ConfigProvider as AntdConfigProvider } from "antd";
import { I18nextProvider } from "react-i18next";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import store from "./store";
import i18next from "localization/i18next";
import App from "./App";

import "./custom-bootstrap.scss";
import "react-loading-skeleton/dist/skeleton.css";
// import "antd/dist/antd.less";
import "antd/dist/antd.variable.min.css";
// styles
import "assets/styles/styles.scss";
import "index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

const direction = "ltr";
const persistor = persistStore(store);

AntdConfigProvider.config({
	theme: {
		primaryColor: "#12819c",
	},
});

root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<I18nextProvider i18n={i18next}>
				<AntdConfigProvider direction={direction}>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</AntdConfigProvider>
			</I18nextProvider>
		</PersistGate>
	</Provider>
);
