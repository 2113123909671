import { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import minusButton from "assets/Icons/minus-button.svg";
import plusButton from "assets/Icons/plus-button.svg";
import tokensIcon from "assets/Icons/coins.svg";
import { sendTokenToProByAdmin } from "../network";
import { toastError, toastSuccess } from "helpers/toasters";
import { map } from "lodash";
import { PlusOutlined } from "@ant-design/icons";

const GrantModal = ({
	isVisible,
	onCancel,
	proId,
	setAllShowenData,
	setPros,
}) => {
	const [tokens, setTokens] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);

	useEffect(() => {
		if (tokens > 0) setIsDisabled(false);
		else setIsDisabled(true);
	}, [tokens]);

	function addRemoveTokens(add = false) {
		if (add) setTokens((prev) => (prev += 1));
		else if (!add && tokens !== 0) setTokens((prev) => (prev -= 1));
		else return;
	}

	function GrantTokenToUser() {
		setIsLoading(true);
		let payload = {
			proId,
			tokens,
		};
		sendTokenToProByAdmin(
			payload,
			(success) => {
				setIsLoading(false);
				toastSuccess(success.message);

				setAllShowenData((prev) => {
					return map(prev, (item) => {
						if (item.userId === proId)
							return {
								...item,
								coinsGrantedByAdmin: item.coinsGrantedByAdmin + tokens,
							};
						else return item;
					});
				});

				setPros((prev) => {
					return map(prev, (item) => {
						if (item.userId === proId)
							return {
								...item,
								coinsGrantedByAdmin: item.coinsGrantedByAdmin + tokens,
							};
						else return item;
					});
				});
				onCancel();
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	return (
		<Modal
			visible={isVisible}
			onCancel={onCancel}
			footer={null}
			centered
			className="grant-modal"
		>
			<div className="grant__container">
				<figure role="button" onClick={() => addRemoveTokens(false)}>
					<img src={minusButton} alt="remove-tokens" className="add-sub" />
				</figure>

				<div className="grant__tokens">
					<div className="tokens">{tokens}</div>
					<img src={tokensIcon} alt="tokens" />
				</div>

				<figure role="button" onClick={() => addRemoveTokens(true)}>
					<img src={plusButton} alt="remove-tokens" className="add-sub" />
				</figure>
			</div>

			<div
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: "2rem",
				}}
			>
				<Button
					style={{
						width: "131px",
						height: "2.5rem",
					}}
					type="primary"
					loading={isLoading}
					onClick={GrantTokenToUser}
					disabled={isDisabled}
				>
					Grant
				</Button>
			</div>
		</Modal>
	);
};

export default GrantModal;
