import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { DownOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Table, Radio } from "antd";
import { useTranslation } from "react-i18next";
import { getAllAdmins } from "../network";
import { toastError } from "helpers/toasters";
import SearchInTable from "modules/components/SearchInTable";
import CustomTooltip from "modules/components/CustomTooltip";
import ActivateUserModal from "../components/ActivateUserModal";
import AddEditAdminModal from "../components/AddEditAdminModal";
import { searchOnTable } from "helpers/searchOnTable";
import { getAllUserRoles } from "../network";

const SuperAdmin = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [adminData, setAdminData] = useState([]);
	const [showenAdminData, setShowenData] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [isActiveUserModalVisible, setIsActiveUserModalVisible] =
		useState(false);
	const [searchVal, setSearchVal] = useState("");
	const [isAddAdminModalVisible, setIsAddAdminModalVisible] = useState(false);
	const [roles, setRoles] = useState([]);
	const {
		authorization: { roleName },
	} = useSelector((state) => state.auth);

	const { t } = useTranslation();
	useEffect(() => {
		getAllUserRoles(
			(success) => {
				success.data.map((item) => {
					item.text = item.name;
					item.value = item._id;
					return item;
				});
				setRoles(success.data);
			},
			(fail) => {
				if (fail.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, []);

	useEffect(() => {
		setIsLoading(true);
		getAllAdmins(
			(success) => {
				setIsLoading(false);
				setAdminData(success.data);
				setShowenData(success.data);
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, []);

	const columns = [
		{
			title: t("userName"),
			dataIndex: "userName",
			key: "userName",
			align: "center",
			width: 200,
			fixed: "left",
			render: (data, user) => {
				return (
					<>
						{roleName === "Super admin" ? (
							<div
								style={{
									textDecoration: "underline",
								}}
								role="button"
								onClick={() => openEditModal(user)}
							>
								<CustomTooltip text={data} count={25} />
							</div>
						) : (
							<CustomTooltip text={data} count={25} />
						)}
					</>
				);
			},
		},
		{
			title: t("name"),
			dataIndex: "name",
			key: "name",
			align: "center",
			width: 150,
			render: (data) => {
				return <CustomTooltip text={data} count={25} />;
			},
		},
		{
			title: t("email"),
			dataIndex: "email",
			key: "email",
			align: "center",
			width: 150,
			render: (data) => {
				return <CustomTooltip text={data} count={25} />;
			},
		},
		{
			title: t("role"),
			dataIndex: "roleName",
			key: "roleName",
			align: "center",
			width: 100,
			filters: roles,
			filterIcon: () => <DownOutlined />,
			onFilter: (value, record) => record.roleId === value,
			render: (data) => {
				return <>{data}</>;
			},
		},
		{
			title: t("activity"),
			dataIndex: "isUserActive",
			key: "isUserActive",
			align: "center",
			width: 100,
			filters: [
				{ text: t("active"), value: true },
				{ text: t("deActive"), value: false },
			],
			filterIcon: () => <DownOutlined />,
			onFilter: (value, record) => record.isUserActive === value,
			render: (isUserActive, userData) => {
				return (
					<Radio
						checked
						onClick={() => openActivateUser(userData)}
						className={!isUserActive ? "in-active-radio" : "active-radio"}
					/>
				);
			},
		},
	];

	function openActivateUser(user) {
		setIsActiveUserModalVisible(true);
		setSelectedUser(user);
	}

	function openEditModal(user) {
		setSelectedUser(user);
		setIsAddAdminModalVisible(true);
	}

	return (
		<div>
			<header className="main-header">
				<h2>{t("superAdmin")}</h2>
			</header>

			<div className="search-date">
				<SearchInTable
					searchVal={searchVal}
					setSearchVal={setSearchVal}
					onSubmit={() =>
						searchOnTable(
							searchVal,
							"userName",
							setIsLoading,
							adminData,
							setShowenData
						)
					}
					className="search-date__search"
				/>

				{roleName === "Super admin" && (
					<div
						className="add-admin"
						role={"button"}
						onClick={() => setIsAddAdminModalVisible(true)}
					>
						<PlusCircleOutlined />
						<span>Add Admin</span>
					</div>
				)}
			</div>

			<Table
				dataSource={showenAdminData}
				columns={columns}
				rowKey={(record) => record.userId}
				bordered
				loading={isLoading}
				size={"middle"}
				scroll={{
					x: "max-content",
					scrollToFirstRowOnChange: true,
				}}
				pagination={{
					pageSize: 10,
				}}
			/>

			{isActiveUserModalVisible && (
				<ActivateUserModal
					visible={isActiveUserModalVisible}
					onCancel={() => setIsActiveUserModalVisible(false)}
					user={selectedUser}
					setUsers={setShowenData}
					setData={setAdminData}
					isAdmin
				/>
			)}

			{isAddAdminModalVisible && (
				<AddEditAdminModal
					roles={roles}
					visible={isAddAdminModalVisible}
					onCancel={() => {
						setIsAddAdminModalVisible(false);
						setSelectedUser(null);
					}}
					setData={setAdminData}
					setShowenData={setShowenData}
					userToEdit={selectedUser}
				/>
			)}
		</div>
	);
};

export default SuperAdmin;
