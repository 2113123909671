import React, { useState, useEffect } from "react";
import { getBlog } from "../../network";
import BlogArticleDetails from "./Components/BlogArticleDetails";
import BlogArticles from "./Components/BlogArticles";
import BlogBanner from "./Components/BlogBanner";

const Blog = () => {
	const [allData, updateAllData] = useState({});
	const [selectedArticle, updateSelectedArticle] = useState(null);
	const [recallArticles, updateRecallArticles] = useState(false);
	useEffect(() => {
		getAllBlogs();
	}, []);
	const getAllBlogs = () => {
		getBlog(
			(success) => {
				updateAllData(success.data);
			},
			(fail) => {}
		);
	};
	return (
		<div className="blogs-page">
			<BlogBanner allData={allData} />
			<BlogArticles
				recallArticles={recallArticles}
				getSelectedArticle={(article) => {
					updateSelectedArticle(article);
				}}
				selectedArticle={selectedArticle}
			/>
			{selectedArticle && (
				<BlogArticleDetails
					clearSelectedArticle={() => {
						updateSelectedArticle(null);
						updateRecallArticles((prev) => !prev);
					}}
					selectedArticle={selectedArticle}
				/>
			)}
		</div>
	);
};

export default Blog;
