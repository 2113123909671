import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "store/authorization";
import ROUTES from "constants/ROUTES";

const LogoutAdmin = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(logout());
		navigate(ROUTES.SIGNIN);
	}, [navigate, dispatch]);

	return <></>;
};

export default LogoutAdmin;
